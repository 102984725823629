import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import classNames from 'classnames';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { BlockInfo, useForms } from 'components/forms/Forms';
import { ModalDialog, showModal } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import {
  SortableContainer,
  SortableInfo,
  SortableItem,
  SortableItemType,
  SortableList,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';
import { IBlock } from 'features/block/blockAPI';
import {
  BlockType,
  ListCookBook,
  ListType,
  emptyBlock,
  listIs,
  patchBlockAsync,
  postBlockAsync,
} from 'features/block/blockSlice';
import { postPatchAndDeleteLists } from 'features/block/listBlockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IList } from 'features/list/listAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { AnyOrderObject, pickPrimitiveProps, sameId } from 'helpers/objects';
import { getOrderForLast, getOrderUsingId, sortByOrder } from 'helpers/sorting';

import './ListSettingsForm.scss';

type ListSettingsFormProps = {
  stateMan: StateMan;
  blockInfo: BlockInfo;
};

function UnconnectedListSettingsForm(
  props: ListSettingsFormProps & FormProps & UserStateProps & DialogueStateProps
) {
  const { show, setShow, blockInfo, userCanEdit, dialogue, stateMan } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [reset, setReset] = useState(true);
  const [data, setData] = useState<IBlock>({});
  const [lists, setLists] = useState<IList[]>([]);
  const [deletedListIds, setDeletedListIds] = useState<number[]>([]);
  const isProCon = listIs(
    blockInfo.listType ||
      (blockInfo.block?.childListBlock?.listType as ListType)
  ).ProCon;
  const isSwot = listIs(
    blockInfo.listType ||
      (blockInfo.block?.childListBlock?.listType as ListType)
  ).SWOT;
  const isKanban = listIs(
    blockInfo.listType ||
      (blockInfo.block?.childListBlock?.listType as ListType)
  ).Kanban;

  const addList = useCallback(
    (name?: string, i?: number) => {
      // if (!blockInfo.block) return;
      const newList: IList = {
        id: i,
        parent: blockInfo.block?.childListBlock || undefined,
        listItems: [],
        coordinate_x: 0,
        coordinate_y: 0,
        name: name || '',
      };
      setLists((prev) => {
        newList.order = getOrderForLast(prev as AnyOrderObject[]);
        return [...prev, newList];
      });
    },
    [blockInfo]
  );

  useEffect(() => {
    if (reset || show) {
      if (blockInfo.block) {
        // existing block
        setData({ ...blockInfo.block });
        if (
          blockInfo.block.childListBlock &&
          blockInfo.block.childListBlock.lists
        )
          setLists(
            sortByOrder(
              blockInfo.block.childListBlock?.lists as (IList &
                AnyOrderObject)[]
            )
          );
        setDeletedListIds([]);
      } else if (blockInfo.phase) {
        // new block
        setData({
          ...emptyBlock,
          childType: BlockType.List,
          phase: blockInfo.phase,
          order: getOrderForLast(
            blockInfo.phase?.blocks as AnyOrderObject[],
            -1
          ),
        });
        setLists([]);
        setDeletedListIds([]);
        // create lists according to the recipe
        const recipe = ListCookBook[blockInfo.listType || ListType.MultiList];
        recipe.listNameIds.forEach((n, i) =>
          // addList(intl.formatMessage({ id: n }), i)
          addList(undefined, i)
        );
      }
      setReset(false);
    }
  }, [addList, blockInfo, intl, reset, show]);

  useEffect(() => {
    const ta = document.getElementById('blockDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 1, 3, true, 4)[0] + 'px';
    }
  }, [show, data]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (data && blockInfo.block) {
          // existing block
          let res: any = await dispatch(
            postPatchAndDeleteLists({
              lists: lists,
              deletedListIds,
              stateMan: stateMan,
            })
          );
          if (res.payload?.response?.status < 300)
            res = await dispatch(
              patchBlockAsync({
                data: {
                  ...pickPrimitiveProps(data),
                },
                stateMan,
              })
            );
          // .then((response: { payload: any }) => {
          switch (res.payload?.response?.status) {
            case 200:
            case 201:
              doFormMsg(
                {
                  message: 'List settings saved',
                  success: true,
                  timeout: 1500,
                },
                setFormMsg
              );
              resolve();
              setShow(false);
              break;
            case 500:
            default:
              const m = 'Internal error, list settings not saved.';
              doFormMsg(
                {
                  message: m,
                  success: false,
                },
                setFormMsg
              );
              reject(m);
              break;
          }
          // });
        } else {
          // new block
          dispatch(
            postBlockAsync({
              data,
              stateMan,
              listNames: lists.map((l) => l.name as string),
              listType: blockInfo.listType,
            })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                doFormMsg(
                  {
                    message: 'List settings saved',
                    success: true,
                    timeout: 1500,
                  },
                  setFormMsg
                );
                resolve();
                setShow(false);
                break;
              case 500:
              default:
                const m = 'Internal error, list settings not saved.';
                doFormMsg(
                  {
                    message: m,
                    success: false,
                  },
                  setFormMsg
                );
                reject(m);
                break;
            }
          });
        }
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log('List update request failed for unclear reason.', err);
        reject();
      }
    });
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    // onCancel && onCancel();
    setReset(true);
    setShow(false);
    return true;
  }

  function handleClose(): void {
    // onClose && onClose();
  }

  function renderListEntry(list: IList, index: number) {
    function handleChangeListData(e: SyntheticEvent) {
      const target = e.target as HTMLInputElement;
      setLists(
        lists.map((l) => {
          if (l.id === list.id) return { ...l, [target.name]: target.value };
          return l;
        })
      );
    }
    function removeList(list: IList) {
      const id: number = list.id!;
      function doDelete(): boolean {
        // add the list id to the deletedListIds array
        setDeletedListIds((prev) => [...prev, id]);
        // remove the list from the lists array
        setLists(lists.filter((l) => l.id !== id));
        return true;
      }
      if (list.listItems?.length)
        showModal(
          <FormattedMessage id="LISTS.FORM.DELETE_LIST" />,
          <>
            <div className="title">
              <FormattedMessage
                id="GENERAL.DELETE.OBJECT.CNF"
                values={{
                  object: intl
                    .formatMessage({ id: 'LISTS.LIST' })
                    .toLowerCase(),
                }}
              />
            </div>
            <div className="text">
              <FormattedMessage
                id="GENERAL.DELETE.OBJECT.CNF2"
                values={{
                  object: intl
                    .formatMessage({ id: 'LISTS.LIST' })
                    .toLowerCase(),
                }}
              />
            </div>
            <Icon
              symbol={IconSymbol.alert}
              variant={IconVariant.accent}
              size={80}
            />
            <div className="text">
              <FormattedMessage id="GENERAL.DELETE.NOTICE" />
            </div>
          </>,
          {
            intl: intl,
            className: 'pd_alert',
            submitBtnText: <FormattedMessage id="X.DELETE" />,
            onSubmit: doDelete,
            editing: true,
            cancelDefault: true,
          }
        );
      else doDelete();
    }

    return (
      <div key={list.id} className="list_entry">
        <div className="list_title">
          <Form.Control
            className="field subsubtitle"
            type="text"
            placeholder={intl.formatMessage({
              id: isProCon
                ? 'LISTS.FORM.PROCON_PLCH'
                : isSwot
                ? `LISTS.SWOT_${index + 1}`
                : isKanban
                ? `LISTS.KANBAN_${index + 1}`
                : 'LISTS.FORM.TITLE_PLCH',
            })}
            name={'name'}
            value={list.name || ''}
            onChange={handleChangeListData}
          />
        </div>
        <div className="list_symbol" key={list.id}>
          {!isSwot && !isKanban && lists.length > 1 ? (
            <Icon
              symbol={IconSymbol.bin}
              className="list_remove"
              onClick={() => removeList(list)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  async function reorderLists(
    sortableInfo: SortableInfo,
    sourceItem: AnyOrderObject,
    sourceList: SortableListInfo,
    destinationItem?: AnyOrderObject,
    destinationList?: SortableListInfo,
    copy?: boolean
  ) {
    const sortedLists = sortByOrder(sourceList.items as AnyOrderObject[]);
    if (!sourceItem || !destinationItem) return;
    const before = destinationItem
      ? ['top', 'left'].includes(
          extractClosestEdge(
            sortableInfo.location.current.dropTargets[0].data
          ) ?? ''
        )
      : false;
    const newOrder = getOrderUsingId(
      sortedLists,
      sourceItem.id!,
      destinationItem.id!,
      before
    );
    setLists((prev) =>
      prev.map((l) => {
        if (sameId(l.id, sourceItem.id)) return { ...l, order: newOrder };
        return l;
      })
    );
  }

  // function reorderLists(result: DropResult, newPos: xyPosition) {
  //   const { source, destination } = result;
  //   if (!destination) return;
  //   if (!lists) return;
  //   const sortedLists = sortByOrder(lists as AnyOrderObject[]);
  //   const moved = sortedLists[source.index];
  //   const dest = sortedLists[destination.index];
  //   if (!moved || !dest) return;
  //   const newOrder = getOrderUsingId(sortedLists, moved.id!, dest.id!);
  //   setLists((prev) =>
  //     prev.map((l) => {
  //       if (sameId(l.id, moved.id)) return { ...l, order: newOrder };
  //       return l;
  //     })
  //   );
  // }

  if (!data) return null;
  if (!userCanEdit(dialogue)) return null;
  const listinfo: SortableListInfo = {
    id: 1,
    listType: SortableListType.Lists,
    items: sortByOrder(lists as AnyOrderObject[]),
    canvas: false,
  };
  const sortedLists = sortByOrder(lists as AnyOrderObject[]);
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form list_settings_form"
      title={
        <FormattedMessage
          id={
            blockInfo.block
              ? 'BLOCKS.FORM.SETTINGS'
              : isProCon
              ? 'LISTS.FORM.FORM_TITLE_PROCON'
              : isSwot
              ? 'LISTS.FORM.FORM_TITLE_SWOT'
              : isKanban
              ? 'LISTS.FORM.FORM_TITLE_KANBAN'
              : 'LISTS.FORM.FORM_TITLE'
          }
        />
      }
      onSubmit={handleSubmit}
      onCancel={userCanEdit(dialogue) ? handleCancel : undefined}
      onClose={handleClose}
      enableEscape
      formMsg={formMsg}
    >
      <Form.Group as={Row} controlId="blockTitle">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field subtitle"
            type="text"
            placeholder={intl.formatMessage({
              id: 'BLOCKS.FORM.TITLE_PLCH',
            })}
            name={'name'}
            value={data.name || ''}
            onChange={handleChangeData}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="blockDescription">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field"
            type="text"
            as="textarea"
            placeholder={intl.formatMessage({
              id: 'LISTS.FORM.DESCRIPTION_PLCH',
            })}
            name={'description'}
            value={data.description || ''}
            onChange={handleChangeData}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="lists">
        <Col sm={12} md={12} lg={12}>
          <div className={classNames('list_settings', `lists_${lists.length}`)}>
            {lists ? (
              <>
                <SortableContainer reorder={reorderLists}>
                  <SortableList
                    listInfo={listinfo}
                    accepting={[SortableItemType.List]}
                    disabled={lists.length <= 1 || isSwot || isKanban}
                    horizontal={true}
                    gap={8}
                  >
                    {sortedLists.map((list, i) => {
                      return (
                        <SortableItem
                          key={list.id}
                          listInfo={listinfo}
                          item={list}
                          itemType={SortableItemType.List}
                          index={i}
                          withHandle={false}
                          disabled={lists.length <= 1 || isSwot || isKanban}
                          horizontal={true}
                          gap={8}
                        >
                          {renderListEntry(list, i)}
                        </SortableItem>
                      );
                    })}
                  </SortableList>
                </SortableContainer>
              </>
            ) : null}
          </div>
          <div className="admin_section">
            <div className="admin_note">
              {isProCon ? (
                <FormattedMessage id="LISTS.FORM.PROCON_INSTR" />
              ) : isSwot || isKanban ? (
                <FormattedMessage id="LISTS.FORM.DEFAULT_INSTR" />
              ) : (
                <FormattedMessage id="LISTS.FORM.TITLE_INSTR" />
              )}
            </div>
            {!isSwot && !isProCon && !isKanban ? (
              <Button
                onClick={() => addList(undefined, 0 - lists.length)}
                variant="outline-secondary"
                disabled={lists.length >= 4}
              >
                <FormattedMessage id="LISTS.FORM.ADD_LIST" />
              </Button>
            ) : null}
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const ListSettingsForm = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedListSettingsForm)
);
export default ListSettingsForm;

export function ListSettingsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  block: IBlock;
  stateMan: StateMan;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, block } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.ListSettingsForm.setCurrentBlockInfo({ block });
    forms.ListSettingsForm.setCurrentStateMan(() => props.stateMan);
    forms.ListSettingsForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div className="form_settings_link" onClick={handleClick}>
      {props.children}
    </div>
  );
}
