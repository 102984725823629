import classNames from 'classnames';
import { ISectionOptions, Paragraph, TextRun } from 'docx';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Page, Text, View } from '@react-pdf/renderer';
import { useAppDispatch } from 'app/hooks';
import { getPhaseUnreadCount } from 'app/unreadCount';
import Block, { BlockToPdf, blockToDocx } from 'components/blocks/Block';
import Collapsable, {
  CollapsableContainer,
} from 'components/collapsable/Collapsable';
import { TextToPara } from 'components/formatters/TextToPara';
import { IconSymbol, IconVariant } from 'components/icons/Icon';
import {
  SortableContainer,
  SortableItem,
  SortableItemType,
  SortableList,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';
import {
  AdminStateProps,
  mapAdminStateToProps,
} from 'features/admin/adminSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { IPhase } from 'features/phase/phaseAPI';
import {
  UiUpdate,
  getPhaseStates,
  persistClosePhase,
  persistOpenPhase,
} from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { IS_DEV } from 'helpers/consts';
import { AnyOrderObject } from 'helpers/objects';
import { sortByOrder } from 'helpers/sorting';
import UnreadCounter from './UnreadCounter';

import { datesToDocx } from 'helpers/export';
import pdfStyles from '../../css/pdfStyles';
import { useDialogueContext } from './DialogueView';
import './Phase.scss';

type Props = {
  dialogue: IDialogue;
  phase: IPhase;
  index: number;
  hideIndex?: boolean;
  stateMan: StateMan;
};

function UnconnectedPhase(props: Props & UserStateProps & AdminStateProps) {
  const {
    dialogue,
    phase,
    userId,
    userIsAdmin,
    userCanEdit,
    showIds,
    index,
    hideIndex = false,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  // const visitedState = getVisitedState('phase', phase.id);
  const unread = getPhaseUnreadCount(phase);
  const { reorderBlocks } = useDialogueContext();
  const phaseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const phStates = getPhaseStates(dialogue);
    const hasVisited = phStates.some((p) =>
      dialogue.phases?.map((dp) => dp.id).includes(p[0])
    );
    if (hasVisited) setOpen(phStates.some((p) => p[0] === phase.id && p[1]));
    else {
      const shouldOpen =
        phase.active ||
        (!phase.fixed &&
          dialogue.phases &&
          index === 0 &&
          !dialogue.phases.some((dp) => dp.active));
      if (shouldOpen) {
        // if dialogue not visited before,
        // by default open the active phase and all blocks in it
        setOpen(true);
        let s: UiUpdate = {
          o: [phase.id ?? 0],
          b: phase.blocks?.map((b) => b.id ?? 0) ?? [],
        };
        dispatch(persistOpenPhase({ data: s, doBlocks: true }));
        // .then(() => {
        //     s = { o: phase.blocks ? phase.blocks.map((b) => b.id ?? 0) : [] };
        //     dispatch(persistOpenBlock({ data: s }));
        //   });
      }
    }
    // }, [dialogue, phase, userId, visitedState]);
  }, [dialogue, dispatch, index, phase, userId]);

  const setOpenPhase: Dispatch<SetStateAction<boolean>> = (newState) => {
    const newS = typeof newState === 'function' ? newState(open) : newState;
    const s: UiUpdate = {
      o: [phase.id ?? 0],
      b: phase.blocks?.map((b) => b.id ?? 0),
    };
    if (newS) {
      dispatch(persistOpenPhase({ data: s, doBlocks: phase.fixed ?? false }));
    } else dispatch(persistClosePhase({ data: s }));
    setOpen(newS);
  };

  function tooltip() {
    return (
      <div className="tooltip_box">
        <div className="title">
          {phase.name ||
            (phase.fixed
              ? intl.formatMessage({
                  id: 'PHASES.LIBRARY',
                })
              : '')}
        </div>
        {!phase.fixed && phase.order && phase.dateBegin ? (
          <div className="period">
            {intl.formatDate(phase.dateBegin, {
              day: 'numeric',
              month: 'short',
            })}
            {phase.dateEnd ? (
              <>
                {' - '}
                {intl.formatDate(phase.dateEnd, {
                  day: 'numeric',
                  month: 'short',
                })}
              </>
            ) : null}
          </div>
        ) : null}
        {phase.description ? (
          <div className="text">
            <TextToPara value={phase.description} />
          </div>
        ) : null}{' '}
      </div>
    );
  }

  function postDropAnimation(element: Element) {
    element.animate(
      [
        {
          backgroundColor: 'rgb(255, 240, 200)',
        },
        {},
      ],
      {
        duration: 1000,
        easing: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
        iterations: 1,
      }
    );
  }

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.phases?.find((ph: IPhase) => ph.id === phase.id);
  }

  const listinfo: SortableListInfo = {
    id: phase.id as number,
    listType: SortableListType.Blocks,
    items: sortByOrder(phase.blocks as AnyOrderObject[]),
    canvas: false,
  };
  return (
    <Collapsable
      className={classNames('phase', {
        hasBlocks: !!phase.blocks?.length,
        open,
        phase_fixed: !!phase.fixed && !!phase.blocks?.length,
      })}
      ref={phaseRef}
    >
      <Collapsable.Controller
        open={open}
        setOpen={setOpenPhase}
        active={phase.active}
        symbol={phase.fixed ? undefined : hideIndex ? undefined : index + 1}
        icon={phase.fixed ? IconSymbol.library : undefined}
        // iconSize={32}
        iconVariant={!!phase.fixed ? IconVariant.normal : IconVariant.light}
        // noIcon={!phase.fixed && hideIndex}
        iconSize={'large'}
        hide={!!phase.fixed && !!phase.blocks?.length}
        tooltip={tooltip}
      >
        <div className="phase_name">
          <div className="phase_name_inner">
            {phase.name ||
              (phase.fixed
                ? intl.formatMessage({
                    id: 'PHASES.LIBRARY',
                  })
                : '')}
            <UnreadCounter unread={unread} />
          </div>
        </div>
        {IS_DEV || (userIsAdmin && showIds) ? (
          <div className="small bottom centre">{phase.id}</div>
        ) : null}
      </Collapsable.Controller>
      <Collapsable.Content open={open}>
        <CollapsableContainer>
          <SortableContainer reorder={reorderBlocks}>
            <SortableList
              key={phase.id}
              listInfo={listinfo}
              accepting={[SortableItemType.Block]}
              className={classNames('blocks', {
                empty: listinfo.items.length === 0,
              })}
              disabled={!userCanEdit(dialogue) || !!phase.fixed}
              horizontal={true}
              gap={16}
            >
              {listinfo.items.length === 0 ? (
                <div className="no_blocks">
                  <FormattedMessage id="BLOCKS.NO_BLOCKS" />
                </div>
              ) : (
                listinfo.items.map((block, i) => {
                  return (
                    <SortableItem
                      key={block.id}
                      listInfo={listinfo}
                      item={block}
                      itemType={SortableItemType.Block}
                      index={i}
                      onCanvas={listinfo.canvas}
                      withHandle={true}
                      disabled={!userCanEdit(dialogue) || !!phase.fixed}
                      horizontal={true}
                      gap={16}
                      postDropAnimation={(ref) => {
                        const elem = ref.current?.querySelector(
                          '.collapsable_controller'
                        );
                        if (elem) postDropAnimation(elem);
                      }}
                    >
                      <Block
                        block={block}
                        phase={phase}
                        phaseNum={hideIndex ? undefined : index}
                        className={classNames('sortable_content', {
                          last: index === listinfo.items.length - 1,
                        })}
                        setOpenPhase={phase.fixed ? setOpenPhase : null}
                        stateMan={stateMan}
                      />
                    </SortableItem>
                  );
                })
              )}
            </SortableList>
          </SortableContainer>
        </CollapsableContainer>
      </Collapsable.Content>
    </Collapsable>
  );
}

const Phase = connect(mapUserStateToProps)(
  connect(mapAdminStateToProps)(UnconnectedPhase)
);
export default Phase;

type PhaseExportProps = {
  dialogue: IDialogue;
  phase: IPhase;
  intl: IntlShape;
  index: number;
};

export function PhaseToPdf(props: PhaseExportProps) {
  const { dialogue, phase, intl, index } = props;
  const sortedBlocks = sortByOrder(phase.blocks as AnyOrderObject[]);
  if (!sortedBlocks.length) return null;
  return (
    <>
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.dialogue_title}>
          <Text>{dialogue.title ?? 'Unnamed dialogue'}</Text>
        </View>
        <View style={pdfStyles.title}>
          <Text>{phase.name ?? 'Unnamed phase'}</Text>
        </View>
        {phase.dateBegin ? (
          <View style={pdfStyles.date}>
            <Text>
              {intl.formatDate(phase.dateBegin, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
              {phase.dateEnd ? (
                <>
                  {' - '}
                  {intl.formatDate(phase.dateEnd, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}
                </>
              ) : null}{' '}
            </Text>
          </View>
        ) : null}
        <View style={pdfStyles.description}>
          <Text>{phase.description}</Text>
        </View>
      </Page>
      {sortedBlocks.map((block, i) => {
        return (
          <Page key={i} style={pdfStyles.page}>
            <BlockToPdf
              dialogue={dialogue}
              phase={phase}
              block={block}
              intl={intl}
              index={i}
            />
          </Page>
        );
      })}
    </>
  );
}

export async function phaseToDocx(
  props: PhaseExportProps
): Promise<ISectionOptions[]> {
  const promise = new Promise<ISectionOptions[]>(async (resolve, reject) => {
    const { dialogue, phase, intl, index } = props;
    let content: ISectionOptions[] = [
      {
        children: [
          new Paragraph({
            children: [new TextRun({ text: 'No content' })],
          }),
        ],
      },
    ];
    const sections: ISectionOptions[] = [
      {
        properties: {},
        children: [
          new Paragraph({
            text: dialogue.title ?? 'Unnamed dialogue',
            style: 'dialogueTitle',
          }),
          new Paragraph({
            text:
              phase.name ||
              (phase.fixed
                ? intl.formatMessage({
                    id: 'PHASES.LIBRARY',
                  })
                : 'Unnamed phase'),
            style: 'Heading1',
          }),
          !phase.fixed && phase.order
            ? datesToDocx(intl, phase.dateBegin, phase.dateEnd) ?? {}
            : {},
          phase.description
            ? new Paragraph({
                text: phase.description,
                style: 'description',
              })
            : {},
        ],
      },
    ] as ISectionOptions[];
    if (phase.blocks) {
      content = [];
      for (const [i, block] of sortByOrder(
        phase.blocks as AnyOrderObject[]
      ).entries()) {
        const b = await blockToDocx({ dialogue, phase, block, intl, index: i });
        content.push(...b);
      }
    }
    sections.push(...content);
    resolve(sections);
  });
  return promise;
}
