// These functions are for retrieving the position of elements, taking scrolling into account.

export type xyPosition = {
  x: number; // x position
  y: number; // y position
  relx?: number; // relative x position as % in container
  rely?: number; //  relative y position as % in container
  invx?: number; // x position from the right of the container
  invy?: number; // y position from the bottom of the container
  cw?: number; // width of the container
  ch?: number; // height of the container
};

export const xyOrigin: xyPosition = { x: 0, y: 0, relx: 0, rely: 0 };
export const LeftRightBoundary = 25;

export function isConsideredLeft(pos: xyPosition): boolean {
  if (!pos?.relx) return false;
  return pos && pos.relx <= LeftRightBoundary;
}

export function getPos(
  element: HTMLDivElement | xyPosition | null | undefined
): xyPosition {
  if (!element) return xyOrigin;
  if (element instanceof HTMLDivElement) {
    const rect = element.getBoundingClientRect();
    return {
      x: rect.x + window.scrollX,
      y: rect.y + window.scrollY,
      relx: 0,
      rely: 0,
    };
  }
  return element;
}

export function addRelativePosInPercentage(
  pos: xyPosition,
  container: HTMLDivElement | xyPosition | null | undefined
) {
  if (!pos || !container || !(container instanceof HTMLDivElement)) return pos;
  const cw = container.clientWidth;
  const ch = container.clientHeight;
  const relx = (100 * pos.x) / cw;
  const rely = (100 * pos.y) / ch;
  return { ...pos, relx, rely, cw, ch };
}

export function addRelativePosFromBottomRight(
  pos: xyPosition,
  container: HTMLDivElement | xyPosition | null | undefined
) {
  if (!pos || !container || !(container instanceof HTMLDivElement)) return pos;
  const cw = container.clientWidth;
  const ch = container.clientHeight;
  const invx = container.clientWidth - pos.x;
  const invy = container.clientHeight - pos.y;
  return { ...pos, invx, invy, cw, ch };
}

export function relativePos(
  element: HTMLDivElement | xyPosition | null,
  container: HTMLDivElement | xyPosition | null
): xyPosition {
  const e = getPos(element);
  const c = getPos(container);
  if (e && c) {
    let p = subtractPos(e, c);
    p = addRelativePosInPercentage(p, container);
    p = addRelativePosFromBottomRight(p, container);
    return p;
  }
  return xyOrigin;
}

export function subtractPos(a: xyPosition, b: xyPosition): xyPosition {
  if (a && b)
    return {
      x: a.x - b.x,
      y: a.y - b.y,
    };
  return xyOrigin;
}
