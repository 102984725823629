import { ColorResult, GithubPicker } from '@hello-pangea/color-picker';
import classNames from 'classnames';
import { ToolHint, ToolHintProps } from 'components/messages/ToolHint';
import { ListColorSchemes, PDColor, PDPalet } from 'helpers/colors';
import {
  CSSProperties,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import './ColorPickerButton.scss';

export const ColorPickerColors: PDPalet = ListColorSchemes.pastel;

/* Helper functions */
function isHex(col: string): boolean {
  return col.indexOf('#') === 0 || col.toLowerCase() === 'transparent';
}
function isName(col: string): boolean {
  return !isHex(col);
}
function asColor(
  col: PDColor | string,
  palet: PDPalet = ColorPickerColors
): PDColor {
  if (typeof col === 'string') {
    if (!isHex(col)) return name2color(col, palet);
    for (const [, color] of Object.entries(palet)) {
      if (color.hex.toUpperCase() === col.toUpperCase())
        return { name: color.name, hex: color.hex };
    }
    return { name: 'undefined', hex: col };
  }
  return col;
}
function name2color(col: string, palet: PDPalet = ColorPickerColors) {
  if (!col) col = 'black';
  for (const [name, color] of Object.entries(palet)) {
    if (name.toUpperCase() === col.toUpperCase())
      return { name: color.name, hex: color.hex };
  }
  return { name: 'undefined', hex: col };
}
function hex2name(col: string, palet?: PDPalet) {
  return isName(col) ? col : asColor(col).name;
}
function name2hex(col: string, palet?: PDPalet) {
  return isHex(col) ? col : asColor(col, palet).hex;
}
function asHex(col: PDColor | string, palet?: PDPalet) {
  if (typeof col === 'string') return name2hex(col, palet);
  return col.hex;
}
function asName(col: PDColor | string, palet?: PDPalet) {
  if (typeof col === 'string') return hex2name(col, palet);
  return col.name;
}

function getContrastBW(color: PDColor | string) {
  const hexcolor = asHex(color).substring(1);
  const r = parseInt(hexcolor.substring(0, 2), 16);
  const g = parseInt(hexcolor.substring(2, 4), 16);
  const b = parseInt(hexcolor.substring(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

/**
 * Shows and handles a button for picking a color.
 */
type ColorPickerButtonProps = {
  className?: string;
  color: PDColor | string;
  setColor: ((col: PDColor | string, palet?: PDPalet) => void) | null;
  palet: PDPalet;
  pickerwidth?: number | ColorPickerWidth;
  width?: number;
  height?: number;
  useHover?: boolean;
  expanded?: boolean;
  insideMenu?: boolean;
  noBW?: boolean;
  noNone?: boolean;
  hintProps?: ToolHintProps;
  // if buttonStyle is provided, the picker button will be rendered as a div with this style
  // otherwise it will be rendered as a bootstrap button with class btn
  buttonStyle?: CSSProperties;
};
type ColorPickerWidth = 'oneRow' | 'twoRows';

export default function ColorPickerButton(
  props: ColorPickerButtonProps & PropsWithChildren
) {
  const {
    className,
    color = ColorPickerColors.blue,
    setColor = null,
    palet = ColorPickerColors,
    pickerwidth = 'oneRow',
    width,
    height,
    useHover = false,
    expanded = false,
    insideMenu = false,
    buttonStyle = {
      borderColor: `transparent transparent ${
        asColor(palet.black, palet).hex
      } transparent`,
    },
    noBW = false,
    noNone = false,
    hintProps,
    children,
  } = props;
  const [show, setShow] = useState(false);
  const [buttonColor, setButtonColor] = useState<string>(asHex(color));
  const ref = useHookWithRefCallback();
  const toolRef = useRef<HTMLDivElement>(null);

  function asExpected(col: PDColor) {
    // return col in the format that the prop color was provided in
    if (typeof color === 'string')
      return isHex(color) ? asHex(col, palet) : asName(col, palet);
    return col;
  }
  function showPicker(s: boolean) {
    setShow(s);
  }

  function useHookWithRefCallback() {
    const ref = useRef<HTMLElement | null>(null);
    const setRef = useCallback((node: HTMLElement | null) => {
      if (ref.current) {
        // Make sure to cleanup any events added to the last instance
        ref.current.removeEventListener('keydown', escFunction, false);
      }
      if (node) {
        // Listen for keydown
        node.addEventListener('keydown', escFunction, false);
      }
      // Update the ref's current property
      ref.current = node;
    }, []);
    return setRef;
  }

  function useMultiRefs<T extends HTMLElement>(
    ...refs: Array<((instance: T | null) => void) | MutableRefObject<T | null>>
  ): MutableRefObject<T | null> {
    const combinedRef = useRef<T | null>(null);
    useEffect(() => {
      refs.forEach((ref) => {
        if (!ref) return;
        if (typeof ref === 'function') {
          ref(combinedRef.current);
        } else {
          ref.current = combinedRef.current;
        }
      });
    }, [refs]);
    return combinedRef;
  }
  const combinedRef = useMultiRefs(ref, toolRef);

  useEffect(() => {
    setButtonColor(asHex(color));
  }, [color]);

  function pickColor(col: ColorResult) {
    const c = asColor(col.hex, palet);
    // set the color in the format that was used for the prop color
    if (setColor) setColor(asExpected(c), palet);
    setShow(false);
  }

  function escFunction(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      setShow(false);
      (e.target as HTMLElement).blur();
    }
  }

  function palet4picker() {
    let cols = Object.entries(palet);
    if (noBW)
      cols = cols.filter(([name]) => !['black', 'white'].includes(name));
    if (noNone) cols = cols.filter(([name]) => !['none'].includes(name));
    return cols.map(([, col]) => col.hex);
  }

  function pickerWidth(): number {
    if (typeof pickerwidth == 'number') return pickerwidth;
    const c = 7 + (noBW ? 0 : 2) + (noNone ? 0 : 1);
    const f = pickerwidth === 'twoRows' ? 0.5 : 1;
    const w = Math.ceil(c * f) * 25 + 2 * 6;
    return w;
  }

  function findColorPicker(el: HTMLElement): HTMLElement | null {
    let e: HTMLElement | null = el;
    while (e) {
      if (e.classList.contains('colorpicker')) return e;
      e = e.parentElement;
    }
    return null;
  }

  const activationProps = {
    onMouseEnter: useHover
      ? () => {
          showPicker(true);
        }
      : undefined,
    onMouseOut: useHover
      ? (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
          const t = findColorPicker(e.relatedTarget as HTMLElement);
          if (!t) showPicker(false);
        }
      : undefined,
    onClick: useHover
      ? undefined
      : () => {
          showPicker(true);
        },
    onBlur: useHover
      ? undefined
      : (e: React.FocusEvent<HTMLDivElement | HTMLButtonElement>) => {
          const t = findColorPicker(e.relatedTarget as HTMLElement);
          if (!t) showPicker(false);
        },
  };

  return (
    <div
      className={classNames(className, {
        colorpicker_inmenu: insideMenu,
        colorpicker_container: !insideMenu,
      })}
    >
      {expanded ? null : (
        <>
          <div
            ref={combinedRef}
            className={classNames('colorpicker_btn', {
              noColor:
                (typeof color === 'string' ? color : color.name) ===
                ColorPickerColors.none.name,
            })}
            style={{ width, height, ...buttonStyle }}
            tabIndex={0}
            {...activationProps}
          >
            {children}
          </div>
          {setColor && !(show || expanded) && hintProps ? (
            <ToolHint {...hintProps} toolRef={combinedRef} />
          ) : null}
        </>
      )}
      {(show || expanded) && setColor ? (
        <GithubPicker
          className="colorpicker"
          color={asHex(color)}
          onChangeComplete={pickColor}
          width={pickerWidth()}
          colors={palet4picker()}
          triangle={'hide'}
        />
      ) : null}
    </div>
  );
}

export { asHex, asColor, asName, getContrastBW };
