import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  BaseEventPayload,
  DragLocationHistory,
  ElementDragPayload,
  ElementDragType,
  Input,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
// import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import classNames from 'classnames';
import React, {
  createContext,
  CSSProperties,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import invariant from 'tiny-invariant';

import { ListType } from 'features/block/blockSlice';
import { AnyOrderObject } from 'helpers/objects';
import {
  isConsideredLeft,
  relativePos,
  subtractPos,
  xyPosition,
} from 'helpers/positions';
import { findAncestor, findChild, sortByOrder } from 'helpers/sorting';
import DropIndicator from './DropIndicator';

import { GetOffsetFn } from '@atlaskit/pragmatic-drag-and-drop/dist/types/public-utils/element/custom-native-drag-preview/types';
import './Sortable.scss';

export enum SortableListType {
  SortableList = 'SortableList',
  None = 'None',
  Dialogues = 'Dialogues',
  Phases = 'Phases',
  Blocks = 'Blocks',
  Lists = 'Lists',
  Items = 'Items',
  Options = 'Options',
  Messages = 'Messages',
  Files = 'Files',
}

export enum SortableItemType {
  SortableItem = 'SortableItem',
  None = 'None',
  Dialogue = 'Dialogue',
  Phase = 'Phase',
  Block = 'Block',
  List = 'List',
  Item = 'Item',
  Option = 'Option',
  Message = 'Message',
  File = 'File',
}

export type SortableListInfo = {
  id: number;
  relatedIds?: number[];
  listType: SortableListType;
  subListType?: ListType;
  items: AnyOrderObject[];
  // block?: IBlock;
  // altItems?: AnyOrderObject[];
  canvas?: boolean;
  locked?: boolean;
};

export type SortableInfo = {
  source: ElementDragPayload;
  location: DragLocationHistory;
  dropPosition: xyPosition;
  delta: xyPosition;
};
type SortableContextValue = {
  instanceId: symbol;
};

const SortableContext = createContext<SortableContextValue | null>(null);

export type ReorderFunction = (
  sortableInfo: SortableInfo,
  item: AnyOrderObject,
  sourceList: SortableListInfo,
  destination?: AnyOrderObject,
  destinationList?: SortableListInfo,
  copy?: boolean
) => void;

type DraggableState =
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement; draggable: HTMLElement | null }
  | { type: 'dragging' };

const idleState: DraggableState = { type: 'idle' };
const draggingState: DraggableState = { type: 'dragging' };

function useSortableContext() {
  const listContext = useContext(SortableContext);
  invariant(listContext !== null);
  return listContext;
}

// This function is copied from pdnd, and corrected:
// it used the container's width and height where it should use the source's
function preserveOffsetOnSource({
  element,
  input,
}: {
  element: HTMLElement;
  input: Input;
}): GetOffsetFn {
  return ({ container }) => {
    const sourceRect = element.getBoundingClientRect();
    // const containerRect = container.getBoundingClientRect();
    const offsetX = Math.min(
      // difference
      input.clientX - sourceRect.x,
      // don't let the difference be more than the width of the container,
      // otherwise the pointer will be off the preview
      // containerRect.width
      sourceRect.width
    );
    const offsetY = Math.min(
      // difference
      input.clientY - sourceRect.y,
      // don't let the difference be more than the height of the container,
      // otherwise the pointer will be off the preview
      // containerRect.height
      sourceRect.height
    );
    return { x: offsetX, y: offsetY };
  };
}

// function logOrders(msg: string, listInfo: SortableListInfo) {
//   console.log(
//     msg,
//     sortByOrder((listInfo.items ?? []) as AnyOrderObject[]).map((i) => i.order)
//   );
// }

// TODO: can we remove items from SortableListInfo ??
// It currently does not get update from redux anyway...
type SortableContainerProps = {
  reorder?: (
    sortableInfo: SortableInfo,
    sourceItem: AnyOrderObject,
    sourceList: SortableListInfo,
    destinationItem?: AnyOrderObject,
    destinationList?: SortableListInfo,
    copy?: boolean
  ) => void;
  blockId?: number;
};

function sameBlock(source: ElementDragPayload, blockId: number): boolean {
  if (source.data.blockId && blockId) return source.data.blockId === blockId;
  return false;
}

function accepts(
  accepting: SortableItemType[],
  itemType: SortableItemType
): boolean {
  return accepting.includes(itemType);
}

export const SortableContainer = React.forwardRef<
  HTMLDivElement,
  SortableContainerProps & PropsWithChildren
>(function SortableContainer(props, ref) {
  const { reorder, blockId, children } = props;
  const [instanceId] = useState(() => Symbol('SortableContainer'));
  const contextValue: SortableContextValue = useMemo(() => {
    return {
      instanceId,
    };
  }, [instanceId]);

  const handleDrop = useCallback(
    ({
      source,
      location,
    }: {
      source: ElementDragPayload;
      location: DragLocationHistory;
    }) => {
      if (
        location.current.dropTargets.length === 0 ||
        instanceId !== location.current.dropTargets[0].data.instanceId
      ) {
        return;
      }
      const sourceList: SortableListInfo = source.data
        .listInfo as SortableListInfo;
      // logOrders('orders on handle', sourceList);
      const sourceItem: AnyOrderObject = source.data.item as AnyOrderObject;
      if (sourceItem) {
        // retrieve the correct destination list record
        // we assume that we are dropping on the innermost item or list,
        // we ignore any dropTargets with i > 1
        // TODO: make this more robust, examine the drop targets and see
        // what we have, a list or an item or both
        const destinationListRecord =
          location.current.dropTargets.length === 1
            ? location.current.dropTargets[0]
            : location.current.dropTargets[1].data.listInfo
            ? location.current.dropTargets[1]
            : location.current.dropTargets[0];
        const destinationList = destinationListRecord.data
          .listInfo as SortableListInfo;
        // and item record
        const destinationItemRecord =
          location.current.dropTargets.length === 1
            ? null
            : location.current.dropTargets[0];
        const destinationItem = destinationItemRecord?.data
          .item as AnyOrderObject;
        let dropPosition: xyPosition = {
          x: location.current.input.clientX,
          y: location.current.input.clientY,
          relx: 0,
          rely: 0,
        };
        dropPosition =
          relativePos(
            dropPosition,
            destinationListRecord.element as HTMLDivElement
          ) ?? dropPosition;
        const delta = subtractPos(
          {
            x: location.current.input.clientX,
            y: location.current.input.clientY,
          },
          {
            x: location.initial.input.clientX,
            y: location.initial.input.clientY,
          }
        );
        if (reorder)
          reorder(
            { source, location, dropPosition, delta },
            sourceItem,
            sourceList,
            destinationItem,
            destinationList,
            !sameBlock(source, blockId ?? 0) && !location.current.input.shiftKey
          );
      }
    },
    [blockId, instanceId, reorder]
  );

  useEffect(() => {
    return combine(
      monitorForElements({
        onDrop: handleDrop,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SortableContext.Provider value={contextValue}>
      <div className="sortable_container" ref={ref}>
        {children}
      </div>
    </SortableContext.Provider>
  );
});

type SortableListProps = {
  listInfo: SortableListInfo;
  accepting?: SortableItemType[];
  className?: string;
  disabled?: boolean;
  horizontal?: boolean;
  copySource?: boolean;
  gap?: number;
  containerRef?: RefObject<HTMLDivElement>;
  blockId?: number;
};

export function SortableList(props: SortableListProps & PropsWithChildren) {
  const {
    listInfo,
    className,
    accepting = [SortableItemType.None],
    disabled = false,
    horizontal = false,
    copySource = false,
    gap = 0,
    containerRef,
    blockId,
    children,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [draggedOver, setDraggedOver] = useState<boolean>(false);
  const { instanceId } = useSortableContext();

  useEffect(() => {
    if (!ref) return;
    invariant(ref.current);
    const cleanUpFns = [
      dropTargetForElements({
        element: ref.current,
        onDragStart: () => setDraggedOver(true),
        onDragEnter: () => setDraggedOver(true),
        onDragLeave: () => setDraggedOver(false),
        onDrop: () => setDraggedOver(false),
        getData: () => ({ listInfo: listInfo, id: listInfo.id, instanceId }),
        getDropEffect: ({ input, source }) =>
          !sameBlock(source, blockId ?? 0) && !input.shiftKey ? 'copy' : 'move',
        getIsSticky: () => true,
        canDrop: ({ input, source }) => {
          if (disabled || copySource) return false;
          return accepts(
            accepting,
            (source.data.type as SortableItemType) ?? SortableItemType.None
          );
        },
      }),
    ];
    if (!listInfo.canvas && containerRef?.current)
      cleanUpFns.push(
        autoScrollForElements({
          element: containerRef.current,
        })
      );
    return combine(...cleanUpFns);
  }, [
    accepting,
    blockId,
    copySource,
    disabled,
    instanceId,
    listInfo,
    containerRef,
  ]);

  return (
    <div
      className={classNames('sortable_list', className, {
        canvas: listInfo.canvas,
        horizontal: horizontal || undefined,
        draggedOver: draggedOver,
        empty: React.Children.count(children) === 0,
      })}
      id={listInfo.id.toString()}
      ref={ref}
      style={{ '--gap': `${gap}px` } as CSSProperties}
    >
      {children}
    </div>
  );
}

type SortableItemProps = {
  listInfo: SortableListInfo;
  item: AnyOrderObject;
  itemType: SortableItemType;
  accepting?: SortableItemType[];
  index: number;
  onCanvas?: boolean;
  className?: string;
  withHandle?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  copySource?: boolean;
  noAnimation?: boolean;
  style?: CSSProperties;
  gap?: number;
  containerRef?: RefObject<HTMLDivElement>;
  previewElementClassName?: string;
  blockId?: number;
  noDropIndicator?: boolean;
  postDropAnimation?: (itemRef: React.RefObject<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent) => void;
  onDrag?: (args: BaseEventPayload<ElementDragType>) => void;
};

export function SortableItem(props: SortableItemProps & PropsWithChildren) {
  const {
    listInfo,
    item,
    itemType,
    accepting = itemType ? [itemType] : [SortableItemType.None],
    className,
    style,
    gap = 0,
    containerRef,
    previewElementClassName,
    withHandle = false,
    disabled = false,
    copySource = false,
    horizontal = false,
    postDropAnimation,
    blockId,
    noDropIndicator = false,
    children,
  } = props;
  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const { instanceId } = useSortableContext();
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [draggableState, setDraggableState] =
    useState<DraggableState>(idleState);
  const [dragPosition, setDragPosition] = useState<xyPosition | null>(null);
  const [draggedOver, setDraggedOver] = useState<boolean>(false);

  const getAvailableEdges = useCallback(
    (sourceId: number, horizontal: boolean): Edge[] => {
      const items = sortByOrder((listInfo.items ?? []) as AnyOrderObject[]);
      if (sourceId === item.id) return [];
      const sourceIndex = items.findIndex((i) => i.id === sourceId);
      const targetIndex = items.findIndex((i) => i.id === item.id);
      const edges: Edge[] = [];
      if (sourceIndex === targetIndex - 1)
        edges.push(horizontal ? 'right' : 'bottom');
      else if (sourceIndex === targetIndex + 1)
        edges.push(horizontal ? 'left' : 'top');
      else if (horizontal) edges.push('left', 'right');
      else edges.push('top', 'bottom');
      return edges;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.order, item.id, listInfo.items]
  );

  useEffect(() => {
    if (!dragRef.current || !dropRef.current) {
      return;
    }
    return combine(
      draggable({
        // element: dragRef.current,
        // dragHandle: handleRef.current || undefined,
        element:
          (withHandle ? handleRef.current : dragRef.current) || dragRef.current,
        canDrag({ input, element, dragHandle }) {
          return !disabled;
        },
        getInitialData: () => ({
          type: itemType,
          id: item.id,
          item: item,
          listInfo: listInfo,
          instanceId,
          blockId,
        }),
        onGenerateDragPreview({ source, location, nativeSetDragImage }) {
          // Use this if we need to generate a different drag
          // image than what the browser delivers standard
          const sortable = withHandle
            ? findAncestor(source.element, 'sortable') ?? source.element
            : source.element;
          if (withHandle)
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: pointerOutsideOfPreview({
                x: '-10px',
                y: '-16px',
              }),
              render({ container }) {
                setDraggableState({
                  type: 'preview',
                  container,
                  draggable: sortable,
                });
                return () => setDraggableState(draggingState);
              },
            });
          else {
            const offset = preserveOffsetOnSource({
              element: sortable ?? source.element,
              input: location.current.input,
            });
            setCustomNativeDragPreview({
              nativeSetDragImage,
              getOffset: offset,
              render({ container }) {
                const o = offset({ container });
                console.log('o:', o);
                setDraggableState({
                  type: 'preview',
                  container,
                  draggable: sortable,
                });
                return () => setDraggableState(draggingState);
              },
            });
          }
        },
        onDragStart: () => {
          setDraggableState(draggingState);
        },
        onDrag: ({ location, source }) => {
          if (containerRef?.current) {
            const dragPos: xyPosition = {
              x: location.current.input.clientX,
              y: location.current.input.clientY,
              relx: 0,
              rely: 0,
            };
            const relDragPos = location.current.dropTargets[1]
              ? relativePos(dragPos, containerRef.current)
              : dragPos;
            setDragPosition(relDragPos);
          }
          if (props.onDrag) props.onDrag({ location, source });
        },
        onDrop: (args) => {
          setDraggableState(idleState);
          setDragPosition(null);
          if (dragRef.current && postDropAnimation) postDropAnimation(dragRef);
        },
      }),
      dropTargetForElements({
        element: dropRef.current,
        getData: ({ input, element, source }) => {
          const data = { type: itemType, item: item, id: item.id, instanceId };
          // const edges = getAvailableEdges(source.data.id as number, horizontal);
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: horizontal ? ['left', 'right'] : ['top', 'bottom'],
            // allowedEdges: edges,
          });
        },
        getIsSticky: () => true,
        getDropEffect: ({ input, source }) =>
          !sameBlock(source, blockId ?? 0) && !input.shiftKey ? 'copy' : 'move',
        onDragEnter: (args) => {
          if (args.source.data.id !== item.id) {
            setClosestEdge(extractClosestEdge(args.self.data));
            setDraggedOver(true);
          }
        },
        onDrag: (args) => {
          if (args.source.data.id !== item.id)
            setClosestEdge(extractClosestEdge(args.self.data));
        },
        onDragLeave: () => {
          setDraggedOver(false);
          setClosestEdge(null);
        },
        onDrop: () => {
          setDraggedOver(false);
          setClosestEdge(null);
        },
        canDrop: ({ input, source }) => {
          if (disabled || copySource) return false;
          return accepts(
            accepting,
            (source.data.type as SortableItemType) ?? SortableItemType.None
          );
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let previewWidth = 0;
  if (draggableState.type === 'preview') {
    draggableState.container.classList.add('sortable_preview');
    const content = draggableState.draggable
      ? findChild(draggableState.draggable, 'sortable_content')
      : draggableState.draggable;
    previewWidth = content?.clientWidth ?? 0;
    // console.log('previewWidth:', previewWidth);
  }
  return (
    <>
      {draggableState.type === 'preview'
        ? //  RENDERING FOR THE PREVIEW IMAGE
          ReactDOM.createPortal(
            <div
              className="sortable_wrapper"
              ref={dropRef}
              style={{
                // width: draggableState.draggable
                //   ? draggableState.draggable.clientWidth // - 8 - 16 //  compensating for margin and padding in css
                //   : undefined,
                width: previewWidth,
                ...style,
              }}
              onClick={props.onClick}
            >
              <div
                ref={dragRef}
                className={classNames('sortable', className, {
                  // onCanvas: onCanvas,
                  dragging: true,
                  noHandle: !withHandle,
                  // copying: makeCopy,
                  draggingLeft: dragPosition && isConsideredLeft(dragPosition),
                  draggingRight:
                    dragPosition && !isConsideredLeft(dragPosition),
                })}
              >
                {/* {withHandle && !disabled ? (
                  <div className="sortable_handle" ref={handleRef} />
                ) : null} */}
                {children}
              </div>
            </div>,
            draggableState.container
          )
        : null}
      <div // RENDERING THE ITEM ITSELF
        className="sortable_wrapper"
        ref={dropRef}
        style={style}
        onClick={props.onClick}
      >
        <div
          ref={dragRef}
          className={classNames('sortable', className, {
            // onCanvas: onCanvas,
            dragging: draggableState === draggingState,
            draggedOver: draggedOver,
            noHandle: !withHandle,
            // copying: makeCopy,
            draggingLeft:
              draggableState === draggingState &&
              dragPosition &&
              isConsideredLeft(dragPosition),
            draggingRight:
              draggableState === draggingState &&
              dragPosition &&
              !isConsideredLeft(dragPosition),
          })}
        >
          {withHandle && !disabled ? (
            <div className="sortable_handle" ref={handleRef} />
          ) : null}
          {children}
          {/* {item.id} - {item.order} */}
        </div>
        {!noDropIndicator && closestEdge ? (
          <>
            <DropIndicator edge={closestEdge} gap={`${gap}px`} />
          </>
        ) : null}
      </div>
    </>
  );
}
