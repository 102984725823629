import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'app/hooks';
import { IBlock } from 'features/block/blockAPI';
import { patchBlockAsync } from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IList } from 'features/list/listAPI';
import { patchListItemAsync } from 'features/list/listItemSlice';
import { IPhase } from 'features/phase/phaseAPI';
import { AnyOrderObject } from 'helpers/objects';
import { getNextOrder, sortByOrder } from 'helpers/sorting';

type ResetListOrderAttributesProps = {
  block: IBlock;
};
type ResetBlockOrderAttributesProps = {
  dialogue: IDialogue;
};

export function resetOrderForItems(items: AnyOrderObject[]): AnyOrderObject[] {
  const newOrders: AnyOrderObject[] = [];
  items.forEach((item) => {
    newOrders.push({ ...item, order: getNextOrder(newOrders, false) });
  });
  return newOrders;
}

export function ResetListOrderAttributes({
  block,
}: ResetListOrderAttributesProps) {
  const dispatch = useAppDispatch();
  function resetOrderForList(list: IList) {
    if (list.listItems) {
      const items = sortByOrder(list.listItems as AnyOrderObject[]);
      resetOrderForItems(items).forEach((item) => {
        dispatch(patchListItemAsync({ data: item, stateMan: () => {} }));
      });
    }
  }
  function resetOrder() {
    if (block.childListBlock?.lists) {
      block.childListBlock.lists?.forEach((list) => {
        resetOrderForList(list);
      });
      alert('All list items in this block had their order attribute reset.');
    }
  }
  return (
    <div className="action_link" onClick={resetOrder}>
      <FormattedMessage id="NAVIGATION.ADMIN_MENU.RESET_ORDER" />
    </div>
  );
}

export function ResetBlockOrderAttributes({
  dialogue,
}: ResetBlockOrderAttributesProps) {
  const dispatch = useAppDispatch();
  function resetOrderForBlocks(phase: IPhase) {
    if (phase.blocks) {
      const items = sortByOrder(phase.blocks as AnyOrderObject[]);
      resetOrderForItems(items).forEach((item) => {
        dispatch(patchBlockAsync({ data: item, stateMan: () => {} }));
      });
    }
  }
  function resetOrder() {
    if (dialogue.phases) {
      dialogue.phases.forEach((phase) => {
        resetOrderForBlocks(phase);
      });
      alert('All blocks in this dialogue had their order attribute reset.');
    }
  }
  return (
    <div className="action_link" onClick={resetOrder}>
      <FormattedMessage id="NAVIGATION.ADMIN_MENU.RESET_ORDER_BLOCKS" />
    </div>
  );
}
