import {
  Document as PDFDocument,
  Page,
  Text,
  View,
  usePDF,
} from '@react-pdf/renderer';
import classNames from 'classnames';
import {
  ISectionOptions,
  Packer,
  Paragraph,
  SectionType,
  TextRun,
  Document as WordDocument,
} from 'docx';
import saveAs from 'file-saver';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { getBlockUnreadCount } from 'app/unreadCount';
import { ResetListOrderAttributes } from 'components/admin/AdminActions';
import Collapsable from 'components/collapsable/Collapsable';
import UnreadCounter from 'components/dialogues/UnreadCounter';
import { ChatSettingsFormLink } from 'components/forms/ChatSettingsForm';
import { DocumentSettingsFormLink } from 'components/forms/DocumentSettingsForm';
import { LibrarySettingsFormLink } from 'components/forms/LibrarySettingsForm';
import { ListSettingsFormLink } from 'components/forms/ListSettingsForm';
import { MeetingSettingsFormLink } from 'components/forms/MeetingSettingsForm';
import { showModal } from 'components/forms/ModalDialog';
import { PollSettingsFormLink } from 'components/forms/PollSettingsForm';
import { SettingCheckbox } from 'components/forms/SettingCheckbox';
import { SudokuSettingsFormLink } from 'components/forms/SudokuSettingsForm';
import Icon, {
  IconSymbol,
  IconVariant,
  getIconSymbol,
} from 'components/icons/Icon';
import DropMenu from 'components/navigation/DropMenu';
import ResizableHandle from 'components/resizable/ResizableHandle';
import {
  AdminStateProps,
  mapAdminStateToProps,
} from 'features/admin/adminSlice';
import { isToolAvailable } from 'features/admin/appSettingsSlice';
import { IBlock } from 'features/block/blockAPI';
import {
  BlockType,
  ListType,
  blockIs,
  deleteBlockAsync,
  patchBlockAsync,
} from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IPhase } from 'features/phase/phaseAPI';
import {
  UiUpdate,
  getBlockStates,
  getVisitedState,
  persistCloseBlock,
  persistOpenBlock,
} from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { IS_DEV } from 'helpers/consts';
import { downloadPdf, sanitizeFileName } from 'helpers/export';
import ChatBlock, { ChatBlockToPdf, chatBlockToDocx } from './ChatBlock';
import DocumentBlock, {
  DocumentBlockToPdf,
  documentBlockToDocx,
} from './DocumentBlock';
import LibraryBlock from './LibraryBlock';
import ListBlock, { ListBlockToPdf, listBlockToDocx } from './ListBlock';
import PollBlock, { PollBlockToPdf, pollBlockToDocx } from './PollBlock';
import SudokuBlock from './SudokuBlock';

import docxStyles from '../../css/docxStyles';
import pfdStyles from '../../css/pdfStyles';
import '../resizable/ResizableHandle.scss';
import './Block.scss';

type BlockProps = {
  block: IBlock;
  phase: IPhase;
  phaseNum?: number;
  className?: string;
  setOpenPhase?: Dispatch<SetStateAction<boolean>> | null;
  absolute?: boolean;
  stateMan?: StateMan;
};

export type ChildBlockProps = {
  block: IBlock;
  showDescription: boolean;
  onUpdate: () => void;
  absolute?: boolean;
  registerDropMenu?: (renderer: RenderChildDropMenu) => void;
  registerIcons?: (renderer: RenderChildIcons, exec?: boolean) => void;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  stateMan: StateMan;
};

export type RenderChildDropMenu = () => JSX.Element;
export type RenderChildIcons = () => JSX.Element;

/* The Block component renders a block as collapsable,
 * with a head, content, and tail part.
 * The rendering of the content and tail is delegated to the child block component.
 */
function UnconnectedBlock(
  props: BlockProps & UserStateProps & DialogueStateProps & AdminStateProps
) {
  const {
    block,
    phase,
    phaseNum,
    absolute,
    setOpenPhase,
    userId,
    userIsAdmin,
    userCanEdit,
    locale,
    showIds,
    dialogue,
    className,
  } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const renderChildDropMenu = useRef<RenderChildDropMenu>();
  const renderChildIcons = useRef<RenderChildIcons>();
  const visitedState = getVisitedState('block', block.id);
  const [unread, setUnread] = useState<number>(0);
  const blockRef = useRef<HTMLDivElement>(null);
  const [pdf, setPdf] = usePDF({ document: <PDFDocument /> });
  const [pdfDownloadReady, setPdfDownloadReady] = useState<boolean>(false);

  useEffect(() => {
    const blStates = getBlockStates(userId);
    setOpen(blStates.some((b) => b[0] === block.id && b[1]));
    const u = getBlockUnreadCount(block);
    setUnread(u);
  }, [block, dispatch, userId, visitedState]);

  function handleUpdate() {
    const u = getBlockUnreadCount(block);
    if (u !== unread) setUnread(u);
  }

  const setOpenBlock: Dispatch<SetStateAction<boolean>> = (newState) => {
    const newS = typeof newState === 'function' ? newState(open) : newState;
    const s: UiUpdate = { o: [block.id ?? 0] };
    if (newS) {
      dispatch(persistOpenBlock({ data: s }));
    } else dispatch(persistCloseBlock({ data: s }));
    setUnread(0);
    setOpen(newS);
  };

  function toggleLockBlock() {
    dispatch(
      patchBlockAsync({
        data: { id: block.id, locked: !block.locked },
        stateMan: blockStateMan,
      })
    );
  }

  function tooltip() {
    return (
      <>
        <div className="tooltip_box">
          <div className="title">{block.name}</div>
          {block.description ? (
            <div className="text">{block.description}</div>
          ) : null}
        </div>
      </>
    );
  }

  function onCollapse(opened: boolean) {
    setOpen(opened);
  }

  function blockStateMan(obj: any): any {
    const o = props.stateMan ? props.stateMan(obj) : null;
    if (o) return o?.blocks?.find((bl: IBlock) => bl.id === block.id);
  }

  useEffect(() => {
    if (pdfDownloadReady && pdf.url && pdf.blob && !pdf.loading) {
      downloadPdf(pdf, block.name ?? block.childType ?? 'unnamed');
      setPdfDownloadReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, pdfDownloadReady]);

  function BlockToPdfLink() {
    return (
      <div
        onClick={() => {
          const newDoc = (
            <PDFDocument pdfVersion="1.3">
              <Page style={pfdStyles.page}>
                {BlockToPdf({ dialogue, phase, block, intl, index: 0 })}
              </Page>
            </PDFDocument>
          );
          setPdf(newDoc);
          setPdfDownloadReady(true);
        }}
      >
        <Icon symbol={IconSymbol.mime_pdf} />
        <div>
          <FormattedMessage id="X.TOPDF" />
        </div>
      </div>
    );
  }

  function BlockToDocxLink() {
    return (
      <div
        onClick={async () => {
          const sections: ISectionOptions[] = await blockToDocx({
            dialogue,
            phase,
            block,
            intl,
            index: -1,
          });
          const newDoc = new WordDocument({
            styles: { ...docxStyles },
            sections: sections,
          });
          Packer.toBlob(newDoc).then((blob) => {
            const fn = block.name ?? block.childType;
            const filename = sanitizeFileName(fn);
            saveAs(blob, `${filename}.docx`);
          });
        }}
      >
        <Icon symbol={IconSymbol.mime_docx} />
        <div>
          <FormattedMessage id="X.TODOCX" />
        </div>
      </div>
    );
  }

  function renderDropMenu() {
    switch (block.childType) {
      case BlockType.Chat:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {userCanEdit(dialogue) ? (
                <>
                  <DropMenu.Note>
                    <FormattedMessage id="NAVIGATION.MODERATION" />
                  </DropMenu.Note>
                  <ChatSettingsFormLink block={block} stateMan={blockStateMan}>
                    <FormattedMessage id="X.SETTINGS" />
                  </ChatSettingsFormLink>
                  <BlockToPdfLink />
                  <BlockToDocxLink />
                  <div onClick={handleRemoveBlock}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.Library:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {userCanEdit(dialogue) ? (
                <>
                  <DropMenu.Note>
                    <FormattedMessage id="NAVIGATION.MODERATION" />
                  </DropMenu.Note>
                  <LibrarySettingsFormLink
                    block={block}
                    stateMan={blockStateMan}
                  >
                    <FormattedMessage id="X.SETTINGS" />
                  </LibrarySettingsFormLink>
                  <div onClick={handleRemoveBlock}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.List:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {IS_DEV || userCanEdit(dialogue) || !block.locked ? (
                <>
                  <DropMenu.Note>
                    <FormattedMessage id="NAVIGATION.FOR_EVERYONE" />
                  </DropMenu.Note>
                  {block.childListBlock ? (
                    <>
                      <SettingCheckbox
                        block={block}
                        stateMan={blockStateMan}
                        prop={'likes'}
                        label={intl.formatMessage({ id: 'LISTS.SHOW_LIKES' })}
                      />
                      <SettingCheckbox
                        block={block}
                        stateMan={blockStateMan}
                        prop={'colors'}
                        label={intl.formatMessage({ id: 'LISTS.SHOW_COLORS' })}
                      />
                      <SettingCheckbox
                        block={block}
                        stateMan={blockStateMan}
                        prop={'attribution'}
                        label={intl.formatMessage({ id: 'LISTS.SHOW_AUTHORS' })}
                      />
                      {IS_DEV ? (
                        <SettingCheckbox
                          block={block}
                          stateMan={blockStateMan}
                          prop={'canvas'}
                          label={intl.formatMessage({
                            id: 'LISTS.SHOW_CANVAS',
                          })}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
              {userCanEdit(dialogue) ? (
                <>
                  <DropMenu.Divider />
                  <DropMenu.Note>
                    <FormattedMessage id="NAVIGATION.MODERATION" />
                  </DropMenu.Note>
                  <ListSettingsFormLink block={block} stateMan={blockStateMan}>
                    <FormattedMessage id="X.SETTINGS" />
                  </ListSettingsFormLink>
                  <BlockToPdfLink />
                  <BlockToDocxLink />
                  <div onClick={handleRemoveBlock}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                </>
              ) : null}
              {userIsAdmin ? <ResetListOrderAttributes block={block} /> : null}
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.Poll:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {userCanEdit(dialogue) ? (
                <>
                  <DropMenu.Note>
                    <FormattedMessage id="NAVIGATION.MODERATION" />
                  </DropMenu.Note>
                  <PollSettingsFormLink block={block} stateMan={blockStateMan}>
                    <FormattedMessage id="X.SETTINGS" />
                  </PollSettingsFormLink>
                  <BlockToPdfLink />
                  <BlockToDocxLink />
                  <div onClick={handleRemoveBlock}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.Document:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {renderChildDropMenu.current && renderChildDropMenu.current()}
              <DropMenu.Items>
                {userCanEdit(dialogue) ? (
                  <>
                    <DropMenu.Divider />
                    <DropMenu.Note>
                      <FormattedMessage id="NAVIGATION.MODERATION" />
                    </DropMenu.Note>
                    <DocumentSettingsFormLink
                      block={block}
                      stateMan={blockStateMan}
                    >
                      <FormattedMessage id="X.SETTINGS" />
                    </DocumentSettingsFormLink>
                    <div onClick={handleRemoveBlock}>
                      <FormattedMessage id="X.DELETE" />
                    </div>
                  </>
                ) : null}
              </DropMenu.Items>
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.Meeting:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              {renderChildDropMenu.current
                ? renderChildDropMenu.current()
                : null}
              <DropMenu.Items>
                {userCanEdit(dialogue) ? (
                  <>
                    <DropMenu.Divider />
                    <DropMenu.Note>
                      <FormattedMessage id="NAVIGATION.MODERATION" />
                    </DropMenu.Note>
                    <MeetingSettingsFormLink
                      block={block}
                      stateMan={blockStateMan}
                    >
                      <FormattedMessage id="X.SETTINGS" />
                    </MeetingSettingsFormLink>
                    <div onClick={handleRemoveBlock}>
                      <FormattedMessage id="X.DELETE" />
                    </div>
                  </>
                ) : null}
              </DropMenu.Items>
            </DropMenu.Items>
          </DropMenu>
        );
      case BlockType.Sudoku:
        return (
          <DropMenu hideWithOpacity={true}>
            <DropMenu.Trigger />
            <DropMenu.Items>
              <DropMenu.Note>
                <FormattedMessage id="NAVIGATION.FOR_EVERYONE" />
              </DropMenu.Note>
              {renderChildDropMenu.current && renderChildDropMenu.current()}
              {userCanEdit(dialogue) ? (
                <>
                  <SudokuSettingsFormLink
                    block={block}
                    stateMan={blockStateMan}
                  >
                    <FormattedMessage id="X.SETTINGS" />
                  </SudokuSettingsFormLink>
                  <div onClick={handleRemoveBlock}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
        );
    }
  }

  function registerDropMenu(renderer: RenderChildDropMenu) {
    renderChildDropMenu.current = renderer;
  }

  function registerIcons(renderer: RenderChildIcons, exec: boolean = false) {
    renderChildIcons.current = renderer;
    if (exec) renderer();
  }

  function handleRemoveBlock() {
    function doDelete(): boolean {
      dispatch(
        deleteBlockAsync({
          block,
          stateMan: props.stateMan || (() => {}), // this is the one from phase!
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 204:
            break;
          case 500:
          default:
            console.log(response);
        }
      });
      return true;
    }
    showModal(
      <FormattedMessage id="BLOCKS.DELETE.TITLE" />,
      <>
        <div className="title">
          <FormattedMessage
            id="BLOCKS.DELETE.CONFIRM"
            values={{
              content: block.name,
            }}
          />
        </div>
        <Icon
          symbol={IconSymbol.alert}
          variant={IconVariant.accent}
          size={80}
        />
        <div className="text">
          <FormattedMessage id="BLOCKS.DELETE.NOTICE" />
        </div>
      </>,
      {
        intl: intl,
        className: 'pd_alert',
        submitBtnText: <FormattedMessage id="BLOCKS.DELETE.DELETE" />,
        cancelBtnText: <FormattedMessage id="BLOCKS.DELETE.NO_DELETE" />,
        onSubmit: doDelete,
        editing: true,
        cancelDefault: true,
      }
    );
  }

  if (!isToolAvailable(block.childType)) return null;
  if (
    block.childType === BlockType.List &&
    !isToolAvailable(block.childListBlock?.listType)
  )
    return null;

  function renderChildBlock() {
    return blockIs(block).Chat ? (
      <ChatBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
      />
    ) : blockIs(block).List ? (
      <ListBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
      />
    ) : blockIs(block).Library ? (
      <LibraryBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
      />
    ) : blockIs(block).Poll ? (
      <PollBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
      />
    ) : blockIs(block).Document ? (
      <DocumentBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
        registerDropMenu={registerDropMenu}
        registerIcons={registerIcons}
      />
    ) : blockIs(block).Sudoku ? (
      <SudokuBlock
        block={block}
        showDescription={true}
        onUpdate={handleUpdate}
        absolute={absolute}
        stateMan={blockStateMan}
        registerDropMenu={registerDropMenu}
        registerIcons={registerIcons}
      />
    ) : (
      <>
        {block.description ? (
          <div className="block_description">{block.description}</div>
        ) : null}
        <div>Blocks of type {block.childType} are not implemented yet</div>
        <div>
          <pre>
            {JSON.stringify(blockIs(block), undefined, 2)}
            <br />
            {JSON.stringify(block, undefined, 2)}
          </pre>
        </div>
      </>
    );
  }

  function extraClasses(block: IBlock): string | null {
    switch (block.childType) {
      case BlockType.Chat:
        return null;
      case BlockType.List:
        if (block.childListBlock?.canvas) return 'lists_canvas';
        if (block.childListBlock?.lists?.length)
          return 'lists_' + block.childListBlock?.lists?.length;
    }
    return null;
  }

  if (absolute) return renderChildBlock();
  return (
    <Collapsable
      key={block.order}
      className={classNames('block', className, {
        locked: block.locked,
      })}
    >
      <Collapsable.Controller
        open={open || !!phase.fixed}
        setOpen={setOpenBlock}
        icon={getIconSymbol(
          (block.childListBlock
            ? block.childListBlock.listType === ListType.MultiList
              ? block.childListBlock.lists &&
                block.childListBlock.lists.length > 1
                ? ListType.MultiList
                : BlockType.List
              : block.childListBlock.listType
            : block.childType) as string
        )}
        iconVariant={IconVariant.normal}
        iconSize={'large'}
        hide={true} // hide controller on open
        onCollapse={onCollapse}
        tooltip={tooltip}
      >
        <UnreadCounter unread={unread} />
        {IS_DEV || (userIsAdmin && showIds) ? (
          <div className="small bottom centre wrap">
            {block.order}
            <br />
            {block.id}
          </div>
        ) : null}
      </Collapsable.Controller>
      <Collapsable.Content open={open || !!phase.fixed} className="resizable">
        <div
          ref={blockRef}
          className={classNames(
            'block_container',
            block.childType?.toLowerCase() + '_block',
            `block_${block.id}`,
            extraClasses(block)
          )}
        >
          <div className="block_head">
            <div className="block_bar">
              <div
                className="block_phase"
                onDoubleClick={() => {
                  setOpenBlock(false);
                  setOpenPhase && setOpenPhase(false);
                }}
              >
                {
                  phase.fixed ? (
                    <>
                      {phase.name ||
                        intl.formatMessage({
                          id: 'PHASES.LIBRARY',
                        })}
                    </>
                  ) : (
                    phase.name
                  )
                  // phaseNum !== undefined ? (
                  //   <>
                  //     <FormattedMessage
                  //       id={dialogue.asRoadmap ? 'PHASES.PHASE' : 'PHASES.PART'}
                  //     />
                  //     {' ' + (phaseNum + 1)}
                  //   </>
                  // ) : (
                  //   <>{phase.name}</>
                  // )
                }
                {userIsAdmin && showIds ? (
                  <div className="right">&nbsp;{block.id}</div>
                ) : null}
              </div>
              {/* {IS_DEV ? <UnreadCounter unread={unread} /> : null} */}
              {userCanEdit(dialogue) ? (
                <Icon
                  className="lock"
                  symbol={block.locked ? IconSymbol.lock : IconSymbol.unlock}
                  variant={block.locked ? IconVariant.dark : IconVariant.normal}
                  hoverVariant={IconVariant.dark}
                  onClick={toggleLockBlock}
                  size={20}
                  hintProps={{
                    hint: intl.formatMessage({
                      id: block.locked ? 'BLOCKS.UNLOCK' : 'BLOCKS.LOCK',
                    }),
                    offset: { x: 0, y: 24 },
                    offsetRight: true,
                  }}
                />
              ) : block.locked ? (
                <Icon
                  className="lock"
                  symbol={IconSymbol.lock}
                  size={20}
                  hintProps={{
                    hint: intl.formatMessage({
                      id: 'BLOCKS.LOCKED',
                    }),
                    offset: { x: 0, y: 24 },
                    offsetRight: true,
                  }}
                />
              ) : null}
              {renderChildIcons.current && renderChildIcons.current()}
              {renderDropMenu()}
              <Icon
                className="collapsable_icon"
                symbol={IconSymbol.minus}
                onClick={() => {
                  setOpenBlock(false);
                  setOpenPhase && setOpenPhase(false);
                }}
              />
            </div>
            <div className="block_title">{block.name}</div>
          </div>
          {absolute ? null : renderChildBlock()}
        </div>
        <ResizableHandle resizeRef={blockRef} />
      </Collapsable.Content>
    </Collapsable>
  );
}

const Block = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(connect(mapAdminStateToProps)(UnconnectedBlock))
);
export default Block;

type BlockExportProps = {
  dialogue: IDialogue;
  phase: IPhase;
  block: IBlock;
  intl: IntlShape;
  index: number;
};

export function BlockToPdf(props: BlockExportProps): React.ReactElement {
  const { dialogue, phase, block, intl, index } = props;
  let content = <Text>No content</Text>;
  switch (block.childType) {
    case BlockType.Chat:
      content = (
        <ChatBlockToPdf block={block} intl={intl} dialogue={dialogue} />
      );
      break;
    case BlockType.List:
      content = (
        <ListBlockToPdf block={block} intl={intl} dialogue={dialogue} />
      );
      break;
    case BlockType.Poll:
      content = (
        <PollBlockToPdf block={block} intl={intl} dialogue={dialogue} />
      );
      break;
    case BlockType.Document:
      content = (
        <DocumentBlockToPdf block={block} intl={intl} dialogue={dialogue} />
      );
      break;
  }
  return (
    <>
      <View style={pfdStyles.dialogue_title}>
        <Text>{dialogue.title ?? ''}</Text>
      </View>
      <View style={pfdStyles.phase_title}>
        <Text>{phase.name ?? ''}</Text>
      </View>
      <View style={pfdStyles.block_header}>
        <View style={pfdStyles.block_title}>
          <Text>{block.name}</Text>
        </View>
        {block.description ? (
          <View style={pfdStyles.block_description}>
            <Text>{block.description}</Text>
          </View>
        ) : null}
        <View style={pfdStyles.block_content}>{content}</View>
      </View>
    </>
  );
}

export async function blockToDocx(
  props: BlockExportProps
): Promise<ISectionOptions[]> {
  const promise = new Promise<ISectionOptions[]>(async (resolve, reject) => {
    const { dialogue, phase, block, intl, index } = props;
    let content: ISectionOptions = {
      children: [
        new Paragraph({
          children: [new TextRun({ text: 'No content' })],
        }),
      ],
    };
    switch (block.childType) {
      case BlockType.Chat:
        content = await chatBlockToDocx({ dialogue, block, intl });
        break;
      case BlockType.List:
        content = await listBlockToDocx({ dialogue, block, intl });
        break;
      case BlockType.Poll:
        content = await pollBlockToDocx({ dialogue, block, intl });
        break;
      case BlockType.Document:
        content = await documentBlockToDocx({ dialogue, block, intl });
        break;
    }
    const sections: ISectionOptions[] = (
      [
        {
          properties: {
            type: index === 0 ? SectionType.CONTINUOUS : SectionType.NEXT_PAGE,
          },
          children: [
            ...(index !== 0
              ? [
                  new Paragraph({
                    text: dialogue.title ?? 'Unnamed dialogue',
                    style: 'dialogueTitle',
                  }),
                  new Paragraph({
                    text:
                      phase.name ||
                      (phase.fixed
                        ? intl.formatMessage({
                            id: 'PHASES.LIBRARY',
                          })
                        : 'Unnamed phase'),
                    style: 'phaseName',
                  }),
                ]
              : []),
            new Paragraph({
              text: block.name ?? 'Unnamed block',
              style: 'Heading2',
              spacing: {
                after: block.description ? 0 : 6 * 20,
              },
            }),
            block.description
              ? new Paragraph({
                  text: block.description,
                  style: 'description',
                })
              : {},
          ],
        },
      ] as ISectionOptions[]
    ).concat(content);
    resolve(sections);
  });
  return promise;
}
