import { Text, View } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Quill, { EmitterSource } from 'quill';
import QuillCursors from 'quill-cursors';
import Delta from 'quill-delta';
import { pdfExporter as quillToPdf } from 'quill-to-pdf';
import * as quillToWord from 'quill-to-word';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { QuillBinding } from 'y-quill';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { getUnreadMessagesCount, registerUnreadCounter } from 'app/unreadCount';
import classNames from 'classnames';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import DropMenu from 'components/navigation/DropMenu';
import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { patchDocumentAsync } from 'features/document/documentSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { ChildBlockProps } from './Block';

import { deepSearchKey } from 'app/AsyncAPI/helpers';
import { ISectionOptions, Paragraph, SectionType } from 'docx';
import { getFileURIByFilePath, postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import { ExportProps } from 'helpers/export';
import {
  stringToUint8Array,
  uint8ArrayToString,
} from 'helpers/uint8array-json';

import '../../css/quill.snow.min.css';
import './DocumentBlock.scss';

// ts-ignore is needed for this line
// due to incorrect type declaration for the register function in Quill v2
// @ts-ignore
Quill.register('modules/cursors', QuillCursors);

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const count = getUnreadMessagesCount(
    // block.childDocumentBlock?.document?.text ?? [],
    [],
    lastVisited,
    'uploadTime'
  );
  return count;
}
registerUnreadCounter(BlockType.Library, getUnread);

function UnconnectedDocumentBlock(
  props: ChildBlockProps & UserStateProps & DialogueStateProps
) {
  const {
    block,
    onUpdate,
    user,
    userCanEdit,
    dialogue,
    registerDropMenu,
    registerIcons,
  } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState<boolean>(false);

  // const currentDocumentBlock = block.childDocumentBlock;
  const currentDocument = block.childDocumentBlock?.document!;

  const roomId = `document_${currentDocument.id}`;

  // const useMountEffect = (fun: EffectCallback) => useEffect(fun, []);

  const container = useRef(null);
  const editor = useRef<Quill>();
  const isSaved = useRef<boolean>();
  const ydocRef = useRef<Y.Doc>();

  const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);

  useEffect(() => {
    if (container.current == null) return;

    if (editor.current == null) {
      editor.current = new Quill(container.current, {
        modules: {
          cursors: true,
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image'],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ['clean'], // remove formatting button
          ],
          history: {
            userOnly: true,
          },
        },
        theme: 'snow',
        formats: quillFormats,
      });

      const ydoc = new Y.Doc();
      ydocRef.current = ydoc;

      const provider = new WebsocketProvider(
        AsyncAPI.webSocketURL,
        roomId,
        ydoc
      );

      // Document text from database is not empty
      if (Object.keys(currentDocument.text).length !== 0) {
        // Convert JSON to Uint8Array
        const parsedJson = JSON.parse(currentDocument.text);
        const uint8ArrayFromJson = stringToUint8Array(parsedJson.data);
        // console.log('JSON converted to Uint8Array:', uint8ArrayFromJson);

        Y.applyUpdate(ydoc, uint8ArrayFromJson);
        isSaved.current = true;
        setHasDataChanged(false);
      }

      const ytext = ydoc.getText('quill');

      const binding = new QuillBinding(
        ytext,
        editor.current,
        provider.awareness
      );

      if (checkExpirationFileURI(editor.current.getContents(), 'image')) save();

      (editor.current.getModule('toolbar') as any).addHandler('image', () => {
        selectLocalImage();
      });

      // Deny pasting of images in the DocBlock
      editor.current.clipboard.addMatcher('IMG', (node, delta) => {
        return new Delta().insert('');
      });
      editor.current.clipboard.addMatcher('PICTURE', (node, delta) => {
        return new Delta().insert('');
      });

      // Define username and cursor color
      provider.awareness.setLocalStateField('user', {
        name: user.username,
        color: cursorColors[Math.floor(Math.random() * cursorColors.length)],
      });

      provider.connect();

      // Handler for editor listener on text change
      const textChangehandler = (
        delta: Delta,
        oldDelta: Delta,
        source: EmitterSource
      ) => {
        if (source === 'api') {
          // console.log('An API call triggered this change.');
        } else if (source === 'user') {
          // console.log('A user action triggered this change.');

          isSaved.current = false;
          setHasDataChanged(true);
        }
      };

      // Editor listeners on text change and selection change
      editor.current.on('text-change', textChangehandler);

      return () => {
        binding.destroy();
        provider.destroy();
        ydoc.destroy();

        editor.current?.off('text-change', textChangehandler);
      };
    }
  }, []);

  useEffect(() => {
    if (dialogue) {
      if (block.childDocumentBlock?.readOnly && !userCanEdit(dialogue)) {
        editor.current?.enable(false);
        setDisabled(true);
      } else {
        editor.current?.enable(!block.locked);
        setDisabled(block.locked ?? false);
      }
    }
  }, [dialogue, block.locked, block.childDocumentBlock?.readOnly, userCanEdit]);

  // Recursive function that deeply searches an object
  // and checks if there any File URIs that are expired,
  // i.e. if the expiry date in the URL is in the past
  // The "isExpired = isExpired || ..." construct is to short-circuit the recursion
  // i.e. stop the recursion if isExpired is already true
  function checkExpirationFileURI(obj: any, keyToSearch: string): boolean {
    let isExpired: boolean = false;

    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (key === keyToSearch) {
          const url = obj[key];

          const expiryDate = extractExpiryDate(url);
          const toDate = new Date(expiryDate!);

          if (new Date() >= toDate) isExpired = true;
        } else if (typeof obj[key] === 'object') {
          isExpired =
            isExpired || checkExpirationFileURI(obj[key], keyToSearch);
        }
      }
    }

    return isExpired;
  }

  // Function to extract encoded expiry date from URL
  function extractExpiryDate(url: string): string | null {
    const regex = /se=(\d{4}-\d{2}-\d{2}T\d{2}%3A\d{2}%3A\d{2}Z)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return decodeURIComponent(match[1]);
    } else {
      return null;
    }
  }

  // Function that searches through the Quill content/Delta
  // and replaces every (old) image URI with a newly refreshed and generated URI
  async function refreshFileURIs() {
    const obj = editor.current?.getContents();
    await replaceFileURI(obj, 'image');
    editor.current?.setContents(new Delta(obj));
  }

  // Recursive function that deeply searches an object
  // and replaces a property's value if the key is the same as the keyToSearch
  // Used to replace old File URIs in a Document with new fresh ones
  // It finds the file_path as a substring inside of the URL,
  // and uses it to do an API call to get a new URI of the File
  async function replaceFileURI(obj: any, keyToReplace: string): Promise<void> {
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (key === keyToReplace) {
          const url = obj[key];
          const file_path = extractSubstring(url);
          const response = await getFileURIByFilePath(file_path!);

          obj[key] = response;
        } else if (typeof obj[key] === 'object') {
          await replaceFileURI(obj[key], keyToReplace);
        }
      }
    }
  }

  /*
  Function to extract substring from URL
    Regex form:  
    Projects/[number]/Dialogues/[number]/UserUploads/[filename].[extension], 

    [number] can be any sequence of digits
    [filename] can contain letters, digits, spaces, and hyphens,
    [extension] can be jpg, jpeg, png, or gif.
  */
  function extractSubstring(url: string) {
    // Regular expression to match the substring pattern
    const regex =
      /Projects\/\d+\/Dialogues\/\d+\/UserUploads\/[\w\s-]+\.(jpg|jpeg|png|gif)/i;
    // Executing the regular expression on the URL
    const match = url.match(regex);
    // If a match is found, return the matched substring, otherwise return null
    return match ? match[0] : null;
  }

  function selectLocalImage() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      if (input.files == null) return;
      const file = input.files[0];

      if (/^image\//.test(file.type)) {
        postFile(file, {
          folder: getFolderName(FolderLocation.Dialogue, 'UserUploads'),
          description: `Image in DocBlock ${currentDocument.id}`,
          order: '',
          mimeType: file.type,
          size: file.size,
          documentFiles: [currentDocument.id!],
        }).then((response) => {
          insertToEditor(response.data.message);
        });
      } else {
        console.warn('You can only upload images.');
      }
    };
  }

  function insertToEditor(url: string) {
    const range = editor.current!.getSelection();
    editor.current!.insertEmbed(range!.index, 'image', url, 'user');
  }

  const saveAsWord = useCallback(async () => {
    if (!editor.current) return;
    const fileName = block.name ?? `Document_${block.id}`;
    const quillDelta = new Delta()
      .insert(fileName, { bold: true, size: 'large' })
      .insert('\n\n')
      .concat(editor.current.getContents());

    if (deepSearchKey(quillDelta!, 'image')) {
      console.log(
        'DocBlock contains image. Export with image is not supported. Aborting...'
      );
      return;
    }

    const quillToWordConfig: quillToWord.Config = {
      exportAs: 'blob',
      paragraphStyles: {
        normal: {
          run: {
            size: 2 * 12, // 12pt font size
          },
          paragraph: {
            spacing: {
              before: 0, // 0pt before
              after: 6 * 20, // 6pt after
              line: 1.1 * 10 * 2 * 12, // 1.1 lineheight
            },
          },
        },
      },
    };
    const docAsBlob = await quillToWord.generateWord(
      quillDelta!,
      quillToWordConfig
    );
    saveAs(docAsBlob as File, fileName);
  }, [block.id, block.name]);

  const saveAsPdf = useCallback(async () => {
    if (!editor.current) return;
    const fileName = block.name ?? `Document_${block.id}`;
    const quillDelta = new Delta()
      .insert(fileName, { bold: true, size: 'large' })
      .insert('\n\n')
      .concat(editor.current.getContents());

    if (deepSearchKey(quillDelta!, 'image')) {
      console.log(
        'DocBlock contains image. Export with image is not supported. Aborting...'
      );
      return;
    }

    const pdfAsBlob = await quillToPdf.generatePdf(quillDelta!);
    saveAs(pdfAsBlob, fileName);
  }, [block.id, block.name]);

  const save = useCallback(() => {
    // Check if there are any expired File URIs
    if (checkExpirationFileURI(editor.current?.getContents(), 'image')) {
      (async () => {
        // Refresh old File URIs with new ones gotten from the backend
        await refreshFileURIs();
        saveHelper();
      })();
    } else {
      saveHelper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (registerDropMenu)
      registerDropMenu(() => {
        return (
          <DropMenu.Items>
            <div onClick={saveAsPdf}>
              <Icon symbol={IconSymbol.mime_pdf} />
              <div>
                <FormattedMessage id="X.TOPDF" />
              </div>
            </div>
            <div onClick={saveAsWord}>
              <Icon symbol={IconSymbol.mime_docx} />
              <div>
                <FormattedMessage id="X.TODOCX" />
              </div>
            </div>
          </DropMenu.Items>
        );
      });
    if (registerIcons)
      registerIcons(() => {
        return hasDataChanged ? (
          <Icon
            className="icon_save"
            symbol={IconSymbol.cloud_saving}
            variant={IconVariant.dark}
            hintProps={{
              hint: 'Saving',
              offset: { x: 0, y: 24 },
              offsetRight: true,
            }}
          />
        ) : (
          <Icon
            className="icon_save"
            symbol={IconSymbol.cloud_saved}
            hintProps={{
              hint: 'Saved',
              offset: { x: 0, y: 24 },
              offsetRight: true,
            }}
            onClick={save}
          />
        );
      }, true);
  }, [
    dialogue,
    hasDataChanged,
    registerDropMenu,
    registerIcons,
    save,
    saveAsPdf,
    saveAsWord,
    userCanEdit,
  ]);

  // Autosave (using setInterval)
  // Save each 5 seconds if there is new data to be saved
  useEffect(() => {
    let autoSaveInterval: NodeJS.Timeout;

    if (hasDataChanged) {
      console.log('Data has changed, starting interval loop');

      autoSaveInterval = setInterval(() => {
        console.log('Save now! (in interval loop)');
        save();
      }, 5000);
    }

    return () => {
      console.log('Clearing interval');
      clearInterval(autoSaveInterval);
    };
  }, [hasDataChanged]);

  // BeforeUnload
  // Show warning as browser pop-up when there are unsaved changes
  useEffect(() => {
    if (!hasDataChanged) return;

    function handleOnBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      // return (event.returnValue = '');
    }

    window.addEventListener('beforeunload', handleOnBeforeUnload, {
      capture: true,
    });

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, {
        capture: true,
      });
    };
  }, [hasDataChanged]);

  // TODO: synchronize this with the author's colors!
  const cursorColors = [
    '#980000',
    '#ff0000',
    '#ff9900',
    '#00ff00',
    '#00ffff',
    '#4a86e8',
    '#0000ff',
    '#9900ff',
    '#ff00ff',
  ];

  // Comment a specific line below to disable that feature in the DocBlock
  const quillFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image',
    // 'video',
  ];

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childDocumentBlock;
  }

  function saveHelper() {
    console.log('Save called');

    if (ydocRef.current == null) return;

    const uint8Array = Y.encodeStateAsUpdate(ydocRef.current);

    // Convert Uint8Array to JSON
    const jsonString = JSON.stringify({ data: uint8ArrayToString(uint8Array) });
    // console.log('Uint8Array converted to JSON:', jsonString);

    dispatch(
      patchDocumentAsync({
        data: {
          text: jsonString,
        },
        id: currentDocument.id!,
        stateMan,
      })
    );

    isSaved.current = true;
    setHasDataChanged(false);
    onUpdate();
  }

  function keyUpListener(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.ctrlKey && e.shiftKey && e.key === 'S') {
      save();
    }
  }

  return (
    <div
      className={classNames('block_content', {
        disabled: disabled,
      })}
    >
      {/* {hasDataChanged ? (
        // <div>
        <Icon
          className="icon_save"
          symbol={IconSymbol.cloud_saving}
          variant={IconVariant.dark}
          hintProps={{ hint: 'Saving', offset: { x: 30 }, offsetRight: true }}
        />
      ) : (
        // </div>
        // <div>
        <Icon
          className="icon_save"
          symbol={IconSymbol.cloud_saved}
          hintProps={{ hint: 'Saved', offset: { x: 30 }, offsetRight: true }}
          onClick={save}
        />
        // </div>
      )} */}
      {props.showDescription && block.description ? (
        <div className="block_description">{block.description}</div>
      ) : null}
      <div className="document_container">
        <div
          className="editor_container"
          ref={container}
          onKeyUp={keyUpListener}
        />
      </div>
      {/* <Button onClick={saveAsWord}>
        <div className="icon-container">
          <span>Download as Word</span> <Icon symbol={IconSymbol.mime_docx} />
        </div>
      </Button>
      <Button onClick={saveAsPdf}>
        <div className="icon-container">
          <span>Download as PDF</span> <Icon symbol={IconSymbol.mime_pdf} />
        </div>
      </Button> */}
    </div>
  );
}

const DocumentBlock = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapDialogueStateToProps)(UnconnectedDocumentBlock)
  )
);
export default DocumentBlock;

export function DocumentBlockToPdf(
  props: {
    block: IBlock;
  } & ExportProps
) {
  const { dialogue, block, intl } = props;
  return (
    <>
      <View>
        <Text>{intl.formatMessage({ id: 'DOCUMENT.SEE_ANNEX' })}.</Text>
      </View>
    </>
  );
}

export async function documentBlockToDocx(
  props: {
    block: IBlock;
  } & ExportProps
): Promise<ISectionOptions> {
  const promise = new Promise<ISectionOptions>(async (resolve, reject) => {
    const { dialogue, block, intl } = props;
    resolve({
      properties: { type: SectionType.CONTINUOUS },
      children: [
        new Paragraph({
          text: intl.formatMessage({ id: 'DOCUMENT.SEE_ANNEX' }) + '.',
        }),
      ],
    });
  });
  return promise;
}
