/* CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.scss';

/* React & libraries */
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  Params,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

/* Helpers */
import ConnectedIntlProvider from 'app/connectedIntlProvider';
import { persistor, store } from 'app/store';
import { SLUGS } from 'helpers/consts';
import { getUserIdFromToken } from 'helpers/helpers';

/* Components */
import DialogueView from 'components/dialogues/DialogueView';
import Layout from 'components/layout/Layout';
import Error404 from 'components/navigation/Error404';
import ProjectOverview from 'components/projects/ProjectOverview';
// import SortableContextProvider from 'components/sortables/SortableContext';
import StaticPage from 'components/static/StaticPage';
import SetLocale from 'components/user/SetLocale';

/* Test components */
import Test from 'test/Test';

/* AsyncAPI */
import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import TryOut from 'components/user/TryOut';

function AppRouter() {
  const routes = [
    {
      path: '/',
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
      loader: async ({
        request,
        params,
      }: {
        request?: Request;
        params?: Params;
      }) => {
        return redirect(
          `/${getUserIdFromToken() ? SLUGS.MYPROJECTS : SLUGS.WELCOME}`
        );
      },
    },
    {
      path: '/en',
      element: (
        <Layout white>
          <SetLocale locale="en" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.WELCOME}`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.TRYOUT}`,
      element: (
        // <Layout restricted fixed>
        <Layout fixed>
          <TryOut />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.TEST}`,
      element: (
        <Layout white>
          <Test />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.MYPROJECTS}`,
      element: (
        <Layout white restricted>
          <ProjectOverview />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.LOGIN}/:code?`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.SIGNUP}/:code?`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.JOIN}/:code?`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.RESETPWD}/:token`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.VERIFYMAIL}/:token/:regcode?`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.GUEST}/:code?`,
      element: (
        <Layout white>
          <StaticPage />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.ABOUT}`,
      element: (
        <Layout white>
          <StaticPage slug={SLUGS.ABOUT} className="help_page" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.CONSENT}`,
      element: (
        <Layout white>
          <StaticPage slug={SLUGS.CONSENT} className="help_page" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.HELP}`,
      element: (
        <Layout white>
          <StaticPage slug={SLUGS.HELP} className="help_page" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.HELP}/${SLUGS.TOOLS}`,
      element: (
        <Layout white>
          <StaticPage slug={SLUGS.TOOLS} className="help_page" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.PRIVACY}`,
      element: (
        <Layout white>
          <StaticPage slug={SLUGS.PRIVACY} className="help_page" />
        </Layout>
      ),
    },
    {
      path: `/${SLUGS.DIALOGUE}/:dialogueId`,
      element: (
        <Layout restricted fixed>
          <DialogueView />
        </Layout>
      ),
    },

    {
      path: '*',
      element: (
        <Layout white>
          <Error404 />
        </Layout>
      ),
    },
  ];
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

export default function App() {
  useEffect(() => {
    const token = Cookies.get('token');

    if (token == null) return;

    AsyncAPI.connect();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedIntlProvider>
          {/* <SortableContextProvider> */}
          <div id="App">
            {/* <RouterProvider router={router} /> */}
            <AppRouter />
          </div>
          {/* </SortableContextProvider> */}
        </ConnectedIntlProvider>
      </PersistGate>
    </Provider>
  );
}
