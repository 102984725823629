import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import {
  DialogueStateProps,
  fetchDialogueAsync,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import { store } from 'app/store';
import Collapsable from 'components/collapsable/Collapsable';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { IBlock } from 'features/block/blockAPI';

import './Test.scss';

function TestPage(
  props: UserStateProps & DialogueStateProps & AppSettingsStateProps
) {
  const { dialogue } = props;
  const dispatch = useAppDispatch();

  const resizeRef = useRef<HTMLDivElement>(null);
  // fetch dialogue 3, phase 3, list block 2
  useEffect(() => {
    dispatch(fetchDialogueAsync(55));
  }, [dispatch]);
  const phase = dialogue.phases?.find((ph) => ph.id === 59);
  const listblock: IBlock | undefined = phase?.blocks?.find(
    (b) => b.id === 149
  );

  const [count, setCount] = useState<number>(0);
  return (
    <>
      <div>Hello {props.user.username}</div>
      <div>Test page content goes here</div>
      <TestObject count={count} />
      <TestButton incrementCount={() => setCount((prev) => prev + 1)} />
    </>
  );
}

function TestButton(props: { incrementCount: () => void }) {
  const { incrementCount } = props;

  return (
    <div
      onClick={incrementCount}
      style={{ border: '1px solid black', width: '100px', cursor: 'pointer' }}
    >
      plus 1
    </div>
  );
}

function TestObject(props: { count: number }) {
  const { count } = props;
  const buttonRef = useRef<HTMLDivElement>(null);

  const getCount = useCallback(() => {
    return count;
  }, [count]);

  useEffect(() => {
    const buttonElem = buttonRef.current;
    function displayCount() {
      alert(getCount());
    }
    if (buttonElem) {
      buttonElem.addEventListener('click', displayCount);
    }
    return () => {
      if (buttonElem) {
        buttonElem.removeEventListener('click', displayCount);
      }
    };
  }, [getCount]);

  return (
    <>
      <div>count: {count}</div>
      <div
        ref={buttonRef}
        style={{ border: '1px solid black', width: '100px', cursor: 'pointer' }}
      >
        getCount
      </div>
    </>
  );
}

function UnconnectedTest(
  props: UserStateProps & DialogueStateProps & AppSettingsStateProps
) {
  const {
    // dialogue,
    settings,
  } = props;
  // const dispatch = useAppDispatch();
  // const [users, setUsers] = useState<IUser[]>([]);
  // const [projectUsers, setProjectUsers] = useState<IUser[]>([]);

  // useEffect(() => {
  //   dispatch(fetchDialogueAsync(58));
  // }, [dispatch]);

  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [openProps, setOpenProps] = useState<boolean>(false);
  const [openRedux, setOpenRedux] = useState<boolean>(false);
  const state = store.getState();

  return (
    <>
      <div className="static_page test">
        <div className="top">
          <Collapsable dimension="height">
            <Collapsable.Controller
              open={openSettings}
              setOpen={setOpenSettings}
            >
              <h1>Settings</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openSettings} dimension="height">
              <div>
                <pre>{JSON.stringify(settings, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
          <Collapsable dimension="height">
            <Collapsable.Controller open={openProps} setOpen={setOpenProps}>
              <h1>Props</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openProps} dimension="height">
              <div>
                <pre>{JSON.stringify(props, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
          <Collapsable dimension="height">
            <Collapsable.Controller open={openRedux} setOpen={setOpenRedux}>
              <h1>Redux State</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openRedux} dimension="height">
              <div>
                <pre>{JSON.stringify(state, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
        </div>
        <TestPage {...props} />
      </div>
    </>
  );
}

const Test = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedTest)
  )
);
export default Test;
