import { Text, View } from '@react-pdf/renderer';
import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import {
  FormattedMessage,
  FormattedTime,
  IntlShape,
  useIntl,
} from 'react-intl';
import { connect } from 'react-redux';

import {
  asColor,
  ColorPickerColors,
} from 'components/colorpicker/ColorPickerButton';
import FormattedDate, { formatDate } from 'components/formatters/FormattedDate';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import PopMenu from 'components/navigation/PopMenu';
import { IBlock } from 'features/block/blockAPI';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
  StateMan,
} from 'features/dialogue/dialogueSlice';
import { deleteListItemAsync } from 'features/list/listItemSlice';
import { IMessage } from 'features/message/messageAPI';
import {
  deleteMessageAsync,
  patchMessageAsync,
} from 'features/message/messageSlice';
import { deleteOptionAsync } from 'features/poll/pollSlice';
import { IUser } from 'features/user/userAPI';
import { mapUserStateToProps, UserStateProps } from 'features/user/userSlice';
import { getColorForId } from 'helpers/colors';
import { IS_DEV } from 'helpers/consts';
import { onSameDay, similarTime } from 'helpers/datetime';
import { ExportProps } from 'helpers/export';
import { hasId, sameId } from 'helpers/objects';
import Author, { authorToDocx, AuthorToPdf } from '../user/Author';
import { confirmDelete } from './confirmDelete';
import { ImageViewLink, ImageViewLinkToPdf } from './ImageViewLink';
import Like, { LikeToPdf } from './Like';
import LinkifyText from './Linkify';
import MessageColor, { MessageColorToPdf } from './MessageColor';
import ReadMore, { ReadMoreToPdf } from './ReadMore';
import TextAreaInput from './TextAreaInput';

import pfdStyles from 'css/pdfStyles';
import {
  BorderStyle,
  ImageRun,
  ITableCellBorders,
  Paragraph,
  TableCell,
  TableRow,
  TabStopPosition,
  TabStopType,
  TextRun,
  WidthType,
} from 'docx';
import {
  fetchImageAsBase64,
  getImageDimensions,
  scaleWidthHeight,
} from 'helpers/images';
import '../../css/forms.scss';
import './Message.scss';

export function getMessageWidthClass(message: IMessage): string {
  const len = message.replyTo
    ? Math.max(
        message.replyTo.content?.length ?? 0,
        message.content?.length ?? 0
      )
    : message.content?.length ?? 0;
  return message.content
    ? message.files?.length
      ? 'msg_width_auto'
      : len > 80
      ? 'msg_width_full'
      : len > 50
      ? 'msg_width_80' // 80%
      : len < 25
      ? 'msg_width_50' // 50%
      : 'msg_width_60' // default is 60%
    : '';
}
type MessageProps = {
  message: IMessage;
  extraContent?: ReactNode | ((message?: IMessage) => ReactNode);
  extraContentClassName?: string;
  className?: string;
  alt?: string;
  block: IBlock;
  blockRef?: React.RefObject<HTMLDivElement>;
  listItemId?: number;
  optionId?: number;
  // setNewReplyTo: Dispatch<SetStateAction<IMessage | null>>;
  setNewReplyTo?: (message: IMessage | null) => void;
  prevTime?: Date;
  prevAuthor?: number;
  stateMan?: StateMan;
  readOnly?: boolean;
  showLikes: boolean;
  showColours?: boolean;
  showAttribution?: boolean;
  editState?: boolean;
  hideEdited?: boolean;
  hideTime?: boolean;
  isNew?: boolean;
  canPin?: boolean;
  pinned?: boolean;
  handlePin?: () => void;
  debug?: boolean;
};

function UnconnectedMessage(
  props: MessageProps & PropsWithChildren & UserStateProps & DialogueStateProps
) {
  const {
    message,
    className,
    block,
    setNewReplyTo,
    showLikes,
    showColours = false,
    showAttribution = true,
    listItemId,
    optionId,
    prevTime,
    prevAuthor,
    readOnly = false,
    editState = false,
    hideEdited = false,
    hideTime = false,
    extraContent,
    extraContentClassName,
    canPin,
    pinned,
    handlePin,
    children,
    debug,
  } = props;
  const { dialogue, userId, userCanEdit, isNew = false } = props;
  const [editing, setEditing] = useState<boolean>(editState);
  const [msg, setMsg] = useState<string>('');
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const byme =
    typeof message.author === 'object' && message.author?.id === userId;

  useEffect(() => {
    setMsg(message.content || '');
  }, [setMsg, message]);

  function submitMessage() {
    dispatch(
      patchMessageAsync({
        data: {
          ...message,
          content: msg,
          edited: true,
        },
        stateMan: stateMan,
      })
    ).then((response: any) => {
      switch (response.payload?.response.status) {
        case 200:
          break;
        default:
      }
      setEditing(editState);
      setMsg(message.content || '');
    });
  }

  function handleChatMsgEdit() {
    message.content && setMsg(message.content);
    setEditing(true);
  }

  function handleChatMsgReply() {
    setNewReplyTo && setNewReplyTo(message);
  }

  function handleChatMsgDelete() {
    function doDelete() {
      if (!!listItemId) {
        dispatch(
          deleteListItemAsync({
            id: listItemId,
            stateMan: (obj: any) => {
              // we come from a list and need to return listItems in here
              const o = props.stateMan ? props.stateMan(obj) : undefined;
              return o?.listItems;
            },
            messageId: message.id!,
          })
        );
      } else if (!!optionId) {
        dispatch(
          deleteOptionAsync({
            id: optionId,
            stateMan: (obj: any) => {
              // we come from a pollBlock and need to return the poll in here
              const o = props.stateMan ? props.stateMan(obj) : undefined;
              return o?.poll;
            },
            messageId: message.id!,
          })
        );
      } else {
        if (message.id && props.stateMan)
          dispatch(
            deleteMessageAsync({
              message: message,
              stateMan: props.stateMan,
            })
          ).then((response: any) => {
            switch (response.payload?.response.status) {
              case 204:
                break;
              case 500:
              default:
                console.log(response);
            }
          });
      }
      return true;
    }
    const isModeration =
      !sameId(message.author, userId) && userCanEdit(dialogue);
    confirmDelete(message.content, isModeration, doDelete, intl);
  }

  function handleMsgChange(input: string, hasText: boolean) {
    setMsg(input);
  }
  function handleBlur() {
    setEditing(editState);
  }

  function stateMan(obj: any): any {
    const o = props.stateMan ? props.stateMan(obj) : undefined;
    const oo = o?.hasOwnProperty('listItems')
      ? o?.listItems?.find((item: any) => item?.message?.id === message.id)
          ?.message
      : o?.hasOwnProperty('poll')
      ? o?.poll?.options?.find((item: any) => item?.message?.id === message.id)
          ?.message
      : o?.messages?.find((m: IMessage) => m?.id === message?.id);
    return oo;
  }

  return (
    <>
      <div
        className={classNames('msg', className, {
          edited: !hideEdited && message.edited,
          attribution: showAttribution,
          byme: byme,
          same_author: showAttribution
            ? !!prevAuthor &&
              sameId(message.author, prevAuthor) &&
              onSameDay(message.time as Date, prevTime)
            : undefined,
          same_time: hideTime
            ? undefined
            : similarTime(message.time as Date, prevTime),
          is_new: isNew,
          // is_replyto: hasId(message.replyTo),
        })}
        data-msg={IS_DEV ? message.id : undefined}
      >
        {
          // userCanEdit(dialogue) ||
          block && !block.locked ? (
            <PopMenu hideWithOpacity={true} noPosition={true}>
              <PopMenu.Items>
                {!readOnly &&
                (userCanEdit(dialogue) || sameId(message.author, userId)) ? (
                  <Icon
                    symbol={IconSymbol.bin}
                    size={16}
                    onClick={handleChatMsgDelete}
                    hoverVariant={IconVariant.dark}
                    hintProps={{
                      hint: intl.formatMessage({ id: 'X.DELETE' }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
                {!readOnly && sameId(message.author, userId) ? (
                  <Icon
                    symbol={IconSymbol.pencil}
                    size={16}
                    onClick={handleChatMsgEdit}
                    hoverVariant={IconVariant.dark}
                    hintProps={{
                      hint: intl.formatMessage({ id: 'CHAT.EDIT' }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
                {!pinned && setNewReplyTo ? (
                  <Icon
                    symbol={IconSymbol.reply}
                    size={16}
                    onClick={handleChatMsgReply}
                    hoverVariant={IconVariant.dark}
                    hintProps={{
                      hint: intl.formatMessage({
                        id: listItemId ? 'LISTS.REPLY' : 'CHAT.REPLY',
                      }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
                {canPin ? (
                  <Icon
                    onClick={handlePin}
                    symbol={pinned ? IconSymbol.unpin : IconSymbol.pin}
                    variant={pinned ? IconVariant.dark : IconVariant.normal}
                    hoverVariant={IconVariant.dark}
                    size={16}
                    hintProps={{
                      hint: intl.formatMessage({
                        id: pinned ? 'LISTS.UNPIN' : 'LISTS.PIN',
                      }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
              </PopMenu.Items>
            </PopMenu>
          ) : null
        }
        <div className="msg_header">
          <>
            {showColours && (
              <MessageColor
                message={message}
                palet={ColorPickerColors}
                stateMan={stateMan}
                editable={!block.locked}
              />
            )}
            {!listItemId ? (
              <Author
                author={message.author as IUser}
                prev={prevAuthor}
                me={byme}
                color={getColorForId(
                  message.author?.id as number,
                  dialogue.subscribers?.map((s) => s.id as number) || []
                )}
              />
            ) : null}
            {hideTime ? null : (
              <div className="msg_time">
                <FormattedTime value={message.time} />
              </div>
            )}
            {pinned ? (
              <Icon
                className="item_pinned"
                symbol={IconSymbol.pin}
                variant={IconVariant.filled}
                size={18}
              />
            ) : null}
          </>
        </div>
        <div className="msg_wrapper">
          <div className="msg_content">
            {message.files?.length ? (
              <ImageViewLink file={message.files[0]} />
            ) : null}
            {hasId(message.replyTo) ? (
              <div className="msg_replyto">
                <Author message={message.replyTo as IMessage} />
                <ReadMore maxLen={55}>
                  {(message.replyTo as IMessage)?.content}
                </ReadMore>
              </div>
            ) : null}
            {editing ? (
              <TextAreaInput
                onChange={handleMsgChange}
                onBlur={handleBlur}
                onSubmit={submitMessage}
                value={msg}
                original={message.content}
                maxrows={6}
                emojiesBtn={true}
              />
            ) : (
              <LinkifyText>{message.content}</LinkifyText>
            )}
          </div>
          {extraContent ? (
            typeof extraContent === 'function' ? (
              extraContent(message)
            ) : (
              <div
                className={classNames('extraContent', extraContentClassName)}
              >
                {extraContent}
              </div>
            )
          ) : null}
          {/* id: {message.id}, {listItemId} */}
        </div>
        <div className="msg_footer">
          {showColours && (
            <MessageColor
              message={message}
              palet={ColorPickerColors}
              stateMan={stateMan}
              editable={!block.locked}
            />
          )}
          {listItemId ? (
            <Author
              author={message.author as IUser}
              me={byme}
              color={getColorForId(
                message.author?.id as number,
                dialogue.subscribers?.map((s) => s.id as number) || []
              )}
            />
          ) : null}
          {!hideEdited && message.edited ? (
            <div className="msg_edited">
              <FormattedMessage id="CHAT.EDITED" />
            </div>
          ) : null}
          {showLikes && (
            <Like
              message={message}
              stateMan={stateMan}
              asDot={!!listItemId}
              editable={!block.locked}
            />
          )}
        </div>
        {children}
      </div>
    </>
  );
}

const Message = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedMessage)
);
export default Message;

export function MessageToPdf(
  props: MessageProps & PropsWithChildren & ExportProps
) {
  const {
    message,
    block,
    showLikes,
    showColours = false,
    listItemId,
    alt,
    optionId,
    prevAuthor,
    hideEdited = false,
    showAttribution = true,
    hideTime = false,
    extraContent,
    extraContentClassName,
    pinned,
    children,
    intl,
    dialogue,
  } = props;
  const authorColor = getColorForId(
    message.author?.id as number,
    dialogue.subscribers?.map((s) => s.id as number) || []
  );
  const replyToAuthorColor = hasId(message.replyTo)
    ? getColorForId(
        message.replyTo?.author?.id as number,
        dialogue.subscribers?.map((s) => s.id as number) || []
      )
    : undefined;
  return (
    <>
      <View style={pfdStyles.message}>
        {hasId(message.replyTo) ? (
          <View style={pfdStyles.message_replyto}>
            <AuthorToPdf
              message={message.replyTo as IMessage}
              color={replyToAuthorColor}
            />
            <ReadMoreToPdf maxLen={55}>
              {(message.replyTo as IMessage)?.content}
            </ReadMoreToPdf>
          </View>
        ) : null}
        <View style={pfdStyles.message_header}>
          <>
            {showColours ? (
              <MessageColorToPdf
                message={message}
                palet={ColorPickerColors}
                editable={!block.locked}
              />
            ) : null}
            {showAttribution && !listItemId ? (
              <AuthorToPdf
                author={message.author as IUser}
                prev={prevAuthor}
                color={authorColor}
              />
            ) : null}
            {!!listItemId || hideTime ? null : (
              <Text style={pfdStyles.time}>
                {intl.formatTime(message.time)}
              </Text>
            )}
            {/* {pinned ? (
              <Icon
                className="item_pinned"
                symbol={IconSymbol.pin}
                variant={IconVariant.filled}
                size={18}
              />
            ) : null} */}
          </>
        </View>
        {message.files?.length ? (
          <View style={pfdStyles.message_image}>
            <ImageViewLinkToPdf file={message.files[0]} />
          </View>
        ) : null}
        <View style={pfdStyles.message_content}>
          <Text>
            {/* <LinkifyText> */}
            {message.content}
            {/* </LinkifyText> */}
          </Text>
          {extraContent ? (
            <View style={pfdStyles.message_extraContent}>
              <Text>
                {typeof extraContent === 'function'
                  ? extraContent(message)
                  : extraContent}
              </Text>
            </View>
          ) : null}
        </View>
        <View style={pfdStyles.message_footer}>
          {!!listItemId && showAttribution ? (
            <AuthorToPdf author={message.author as IUser} color={authorColor} />
          ) : null}
          {!hideEdited && message.edited ? (
            <Text style={pfdStyles.message_edited}>
              {intl.formatMessage({ id: 'CHAT.EDITED' })}
            </Text>
          ) : null}
          {showLikes ? (
            <LikeToPdf message={message} asDot={!!listItemId} />
          ) : null}
        </View>
        {children}
      </View>
    </>
  );
}

export async function messageToDocx(
  props: MessageProps & { children?: Paragraph[] } & ExportProps
): Promise<TableRow> {
  const promise = new Promise<TableRow>(async (resolve, reject) => {
    const {
      dialogue,
      block,
      message,
      alt,
      showLikes,
      showColours = false,
      listItemId,
      optionId,
      prevAuthor,
      hideEdited = false,
      showAttribution = true,
      hideTime = false,
      extraContent,
      extraContentClassName,
      pinned,
      children = [],
      intl,
    } = props;
    const borders: ITableCellBorders | undefined = listItemId
      ? {
          bottom: { style: BorderStyle.SINGLE, size: 2, color: '999999' },
        }
      : undefined;
    const palet = ColorPickerColors;
    const leftBorder: ITableCellBorders | undefined =
      listItemId && showColours && message.color !== 'none'
        ? {
            left: {
              style: BorderStyle.SINGLE,
              size: 12 * 5,
              color: asColor(message.color || palet.black, palet).hex,
            },
          }
        : undefined;
    const authorColor = getColorForId(
      message.author?.id as number,
      dialogue.subscribers?.map((s) => s.id as number) || [],
      { asHex: true }
    );
    const replyToAuthorColor = hasId(message.replyTo)
      ? getColorForId(
          message.replyTo?.author?.id as number,
          dialogue.subscribers?.map((s) => s.id as number) || [],
          { asHex: true }
        )
      : undefined;
    const cells: TableCell[] = [];
    if (showAttribution)
      cells.push(
        new TableCell({
          width: {
            size: '15%',
            type: WidthType.PERCENTAGE,
          },
          children: [
            authorToDocx({
              author: message.author,
              color: authorColor,
            }),
          ],
          borders: { ...borders, ...leftBorder },
          margins: showColours && !!listItemId ? { left: 6 * 20 } : undefined,
        })
      );
    const replyTo = message.replyTo
      ? [
          authorToDocx({
            author: message.replyTo.author,
            color: replyToAuthorColor,
            indent: true,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: message.replyTo.content,
              }),
            ],
            style: 'messageReplyTo',
            indent: { left: 6 * 20 },
          }),
        ]
      : [];
    let file: Paragraph[] = [];
    if (message.files?.length && message.files[0].uri) {
      const fileData = await fetchImageAsBase64(message.files[0].uri);
      const { width, height } = await getImageDimensions(
        fileData.base64,
        fileData.mimeType
      );
      const scaled = scaleWidthHeight(width, height, 400, 300);
      file = [
        new Paragraph({
          children: [
            new ImageRun({
              data: fileData.base64,
              transformation: {
                width: scaled.width,
                height: scaled.height,
              },
            }),
          ],
        }),
      ];
    }
    const extra = extraContent
      ? [
          new Paragraph({
            text:
              typeof extraContent === 'function'
                ? extraContent()?.toString()
                : extraContent.toString(),
          }),
        ]
      : [];
    cells.push(
      new TableCell({
        children: [
          ...replyTo,
          ...file,
          new Paragraph({
            text: message.content + (optionId ? '\t' + alt : ''),
            style:
              alt === 'pro'
                ? 'message_pro'
                : alt === 'con'
                ? 'message_con'
                : 'message',
            tabStops: optionId
              ? [{ type: TabStopType.END, position: TabStopPosition.MAX }]
              : [],
          }),
          ...extra,
          ...children,
        ],
        borders: { ...borders, ...(showAttribution ? {} : leftBorder) },
        margins: showAttribution
          ? { right: 6 * 20 }
          : { left: 6 * 20, right: 6 * 20 },
      })
    );
    if (!listItemId && !hideTime)
      cells.push(
        new TableCell({
          width: {
            size: '8%',
            type: WidthType.PERCENTAGE,
          },
          children: [
            new Paragraph({
              text: intl.formatTime(message.time),
              style: 'messageTime',
            }),
          ],
          borders,
        })
      );
    if (showLikes)
      cells.push(
        new TableCell({
          width: {
            size: '3%',
            type: WidthType.PERCENTAGE,
          },
          children: [
            new Paragraph({
              text: message.likes?.length
                ? message.likes?.length.toString()
                : '',
              style: 'messageLikes',
            }),
          ],
          borders,
        })
      );
    resolve(
      new TableRow({
        children: cells,
      })
    );
  });
  return promise;
}

type MessageDateProps = {
  first?: boolean;
  date: Date;
  last: Date;
  unread?: number;
};
export function MessageDate(props: MessageDateProps) {
  const { first, last, date, unread = 0 } = props;
  const addDate = !onSameDay(last, new Date(date));
  const unrd = unread ? (
    <div className="msg_unread">
      <div>
        <FormattedMessage id="CHAT.UNREAD" values={{ count: unread }} />
      </div>
    </div>
  ) : (
    <></>
  );
  if (addDate) {
    return (
      <>
        {unrd}
        <div className={classNames('msg_date', { first: first })}>
          {onSameDay(new Date(date), new Date()) ? (
            <FormattedMessage id="CHAT.TODAY" />
          ) : (
            <FormattedDate
              value={new Date(date)}
              format={{
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              }}
              showYear={false}
            />
          )}
        </div>
      </>
    );
  }
  return unrd;
}

export function MessageDateToPdf(
  props: MessageDateProps & { intl: IntlShape }
) {
  const { last, date, intl } = props;
  const addDate = !onSameDay(last, new Date(date));
  if (addDate) {
    const d = formatDate(
      {
        value: new Date(date),
        format: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        },
        showYear: true,
      },
      intl
    );
    return (
      <View style={pfdStyles.message_date}>
        <Text>{d}</Text>
      </View>
    );
  }
  return null;
}

export function messageDateToDocx(
  props: MessageDateProps & { intl: IntlShape }
): TableRow | null {
  const { date, last, intl } = props;
  const addDate = !onSameDay(last, new Date(date));
  if (addDate) {
    const d = formatDate(
      {
        value: new Date(date),
        format: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        },
        showYear: true,
      },
      intl
    );
    return new TableRow({
      children: [
        new TableCell({
          columnSpan: 4,
          shading: {
            fill: 'D1D1D1',
          },
          children: [
            new Paragraph({
              text: d,
              style: 'messageDate',
            }),
          ],
        }),
      ],
    });
  }
  return null;
}
