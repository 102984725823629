import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/closest-edge';
import { CSSProperties } from 'react';

interface DropIndicatorProps {
  edge: Edge;
  gap: string;
}

function DropIndicator({ edge, gap }: DropIndicatorProps) {
  const edgeClassMap: Partial<{ [key in DropIndicatorProps['edge']]: string }> =
    {
      top: 'edge-top',
      bottom: 'edge-bottom',
      left: 'edge-left',
      right: 'edge-right',
    };

  const edgeClass = edgeClassMap[edge];

  const style = {
    '--gap': gap,
  } as CSSProperties;

  return <div className={`drop-indicator ${edgeClass}`} style={style}></div>;
}

export default DropIndicator;
