import { RefObject, useEffect, useState } from 'react';

import classNames from 'classnames';
import './ResizableHandle.scss';

interface ResizableHandleProps {
  onResize?: (deltaWidth: number) => void;
  onResizeEnd?: (width: number) => void;
  resizeRef: RefObject<HTMLDivElement>;
  min?: number; // minimum width
  max?: number; // maximum width
  // note that setting the min and max width in css for the target element
  // will have preference, if they are larger and smaller respectively
}

const ResizableHandle: React.FC<ResizableHandleProps> = (
  props: ResizableHandleProps
) => {
  const { onResize, onResizeEnd, resizeRef, min = 0, max = 0 } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  function handleMouseDown(event: React.MouseEvent) {
    setIsDragging(true);
    setStartX(event.clientX);
    if (resizeRef.current) {
      // adding a classname on the object being resized, in case we need it
      resizeRef.current.classList.add('resizing');
      // prevent text selection during resizing
      document.body.style.userSelect = 'none';
    }
  }
  function handleDoubleClick() {
    // this resets the default width
    if (resizeRef.current) resizeRef.current.style.width = '';
  }

  useEffect(() => {
    function handleMouseMove(event: MouseEvent) {
      if (!isDragging || !resizeRef.current) return;
      const deltaWidth = event.clientX - startX;
      const prevWidth = resizeRef.current.clientWidth;
      const w = Math.max(
        min,
        max ? Math.min(max, prevWidth + deltaWidth) : prevWidth + deltaWidth
      );
      resizeRef.current.style.width = w + 'px';
      if (onResize) onResize(deltaWidth);
      setStartX(event.clientX);
    }

    function handleMouseUp() {
      setIsDragging(false);
      if (resizeRef.current) {
        if (onResizeEnd) onResizeEnd(resizeRef.current.clientWidth);
        resizeRef.current?.classList.remove('resizing');
        // remove the userSelect attribute
        document.body.style.userSelect = '';
      }
    }

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX, onResize, resizeRef, onResizeEnd, min, max]);

  return (
    <div
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      className={classNames('resizable_handle', {
        dragging: isDragging,
      })}
    />
  );
};

export default ResizableHandle;
