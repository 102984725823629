import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import {
  deleteMessage,
  IMessageCreateReply,
  postMessage,
} from 'features/message/messageAPI';
import { IUser } from 'features/user/userAPI';
import { DeepFindObjectType, reFindObject } from 'helpers/finders';
import { sameId } from 'helpers/objects';
import {
  deleteOption,
  deleteVote,
  IOption,
  IOptionCreate,
  IPoll,
  IVoteCreate,
  patchOption,
  patchPoll,
  postOption,
  postPoll,
  postVote,
} from './pollAPI';

export const postPollAsync = createAsyncThunk(
  'poll/patchPoll',
  async (
    {
      data,
      stateMan,
    }: {
      data: IPoll;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await postPoll(data), stateMan: stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchPollAsync = createAsyncThunk(
  'poll/patchPoll',
  async (
    {
      data,
      stateMan,
    }: {
      data: IPoll;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchPoll(data), stateMan: stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postOptionAsync = createAsyncThunk(
  'option/postOption',
  async (
    {
      data,
      pollId,
      order,
      author,
      file,
      stateMan,
    }: {
      data: IMessageCreateReply;
      pollId: number;
      order: string;
      author: IUser;
      file?: File & { data?: any };
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      let res;
      if (data.files != null && data.files.length !== 0) {
        const file: any = data.files[0];
        data.filesAdd = [{ id: file.id }];
        res = await postMessage(data);
        res.data.files = [{ ...file }];
      } else if (file) {
        const fd = await postFile(file, {
          description: 'User upload',
          folder: getFolderName(FolderLocation.Dialogue, 'UserUploads'),
          order: '',
          size: file.size,
          mimeType: file.type,
        });
        data.filesAdd = [{ id: fd.data._id }];
        file.data = [
          {
            description: 'User upload',
            id: fd.data._id,
            fileName: file.name,
            mimeType: file.type,
            order: '',
            size: file.size,
            uri: fd.data.message,
          },
        ];
        res = await postMessage(data);
        // res.data.files = [{ id: fd.data._id, uri: fd.data.message }];
      } else res = await postMessage(data);
      const message = { ...data, id: res.data._id, files: res.data.files };
      let option: IOptionCreate = {
        poll: pollId,
        message: res.data._id,
        order: order,
        votes: [],
      };
      return {
        response: await postOption(option),
        stateMan,
        option,
        message,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchOptionAsync = createAsyncThunk(
  'option/patchOption',
  async (
    {
      data,
      stateMan,
    }: {
      data: IPoll;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchOption(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteOptionAsync = createAsyncThunk(
  'option/deleteOption',
  async (
    {
      id,
      stateMan,
      messageId,
    }: {
      id: number;
      stateMan: StateMan;
      messageId: number;
    },
    thunkAPI
  ) => {
    try {
      // let deleteMessageResponse = await deleteMessage(id);
      return {
        // TODO: backend call for deleting list item and message at once
        // TODO: same for lists, blocks, phases, dialogues, projects, etc.
        response: await deleteOption(id).then((response) => {
          if (response.status === 204) {
            deleteMessage(messageId);
          }
        }),
        stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postVoteAsync = createAsyncThunk(
  'vote/postVote',
  async (
    {
      data,
      stateMan,
      user,
    }: {
      data: IVoteCreate;
      stateMan: StateMan;
      user: IUser;
    },
    thunkAPI
  ) => {
    try {
      return {
        response: await postVote(data),
        stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteVoteAsync = createAsyncThunk(
  'vote/deleteVote',
  async (
    {
      id,
      stateMan,
      userId,
    }: {
      id: number;
      stateMan: StateMan;
      userId: number;
    },
    thunkAPI
  ) => {
    try {
      return {
        response: await deleteVote(id),
        stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

type MoveOptionProps = {
  from: DeepFindObjectType;
  to: DeepFindObjectType;
  data: IOption;
  stateMan: StateMan;
};

export function addOptionReducers() {
  return {
    moveOption: (state: any, action: PayloadAction<MoveOptionProps>) => {
      const data = { ...action.payload.data };
      const stateObj = action.payload.stateMan(state);
      const fromObj = reFindObject(action.payload.from, stateObj);
      const toObj = reFindObject(action.payload.to, stateObj);
      if (!fromObj || !fromObj.object) return;
      if (!toObj || !toObj.list) return;
      const fromItems = (fromObj.list as IPoll).options;
      const toItems = (toObj.list as IPoll).options;
      if (!fromItems || !toItems) return;
      if (!sameId(fromObj.list, toObj.list)) {
        // we're moving between lists
        // get the index from the unsorted array
        const unsortedFromIndex = fromItems.findIndex(
          (i) => i.id === fromObj.object!.id
        );
        // update the lists
        fromItems.splice(unsortedFromIndex, 1);
        // add the item to the new list
        toItems.push(data);
        // update the list attribute
        data.poll = toObj.list;
      }
      const item = fromObj?.object;
      if (item) Object.assign(item, data);
    },
  };
}

export function addPollCases(builder: ActionReducerMapBuilder<IDialogueState>) {
  builder
    .addCase(patchPollAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchPollAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const pollBl = stateObj.childPollBlock;
      if (pollBl) {
        const poll = pollBl.poll;
        if (poll) Object.assign(poll, action.meta.arg.data);
      }
    })
    .addCase(patchPollAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(patchOptionAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchOptionAsync.fulfilled, (state, action) => {
      state.status = 'idle';
    })
    .addCase(patchOptionAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postOptionAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postOptionAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.payload.response.data._id;
      const poll = stateObj.hasOwnProperty('options')
        ? stateObj
        : stateObj.hasOwnProperty('poll')
        ? stateObj.poll
        : null;
      if (poll && poll.options) {
        poll.options.push({
          ...action.payload.option,
          id: id,
          message: {
            ...action.payload.message,
            author: action.meta.arg.author,
            files: action.meta.arg.file?.data ?? action.payload.message.files,
          },
        });
      } else {
        console.log(
          'Oh nooo, your new option got lost! But no worries: we saved it already. Just refresh the page.'
        );
      }
    })
    .addCase(postOptionAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteOptionAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteOptionAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const poll = stateObj.hasOwnProperty('options')
        ? stateObj
        : stateObj.hasOwnProperty('poll')
        ? stateObj.poll
        : null;
      if (poll && poll.options) {
        const id = action.meta.arg.id;
        poll.options.splice(
          poll.options.findIndex((o: any) => o.id === id),
          1
        );
      }
    })
    .addCase(deleteOptionAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postVoteAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postVoteAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.payload.response.data._id;
      const poll = stateObj.hasOwnProperty('options')
        ? stateObj
        : stateObj.hasOwnProperty('poll')
        ? stateObj.poll
        : null;
      if (poll && poll.options) {
        const option = poll.options.find(
          (o: IOption) => o.id === action.meta.arg.data.option
        );
        if (option) {
          option.votes.push({
            id: id,
            voted: action.meta.arg.data.voted,
            voter: action.meta.arg.user,
          });
        }
      } else {
        console.log(
          'Oh nooo, your vote got lost! But no worries: we saved it already. Just refresh the page.'
        );
      }
    })
    .addCase(postVoteAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteVoteAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteVoteAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const poll = stateObj.hasOwnProperty('options')
        ? stateObj
        : stateObj.hasOwnProperty('poll')
        ? stateObj.poll
        : null;
      if (poll && poll.options) {
        poll.options.forEach((o: IOption) => {
          const i =
            o.votes?.findIndex((v: any) => v.id === action.meta.arg.id) ?? -1;
          if (i >= 0) o.votes?.splice(i, 1);
        });
      }
    })
    .addCase(deleteVoteAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
